import React from 'react';
import RegisterWidget from './RegisterWidget';
// import RegisterModalWidget from './RegisterModalWidget';
// import OtherVolumesWidget from './OtherVolumesWidget';
import LoginWidget from './LoginWidget';

const SideWidgets = ({
  Login,
  Register
  // newsViewsVolumeNumber,
  // registerModalOpen,
  // setRegisterModalOpen
}) => {
  return (
    <div className="mobile-widget-margin">
      {Login && <LoginWidget />}
      {Register && <RegisterWidget />}
      {/* {RegisterModal && (
        <RegisterModalWidget
          registerModalOpen={registerModalOpen}
          setRegisterModalOpen={setRegisterModalOpen}
        />
      )} */}
      {/* {newsViewsVolumeNumber && <OtherVolumesWidget currentVolumeNumbers={newsViewsVolumeNumber} />} */}
    </div>
  );
};

export default SideWidgets;
