/* eslint-disable no-shadow */
import React, { useState } from 'react';

import * as Sentry from '@sentry/react';
import { hot } from 'react-hot-loader/root';
import { slide as SlideMenu } from 'react-burger-menu';
import { Modal } from 'antd';

import { RightOutlined } from '@ant-design/icons';

import ReactGA from 'react-ga';

import { Switch, Route, withRouter, Link, Redirect } from 'react-router-dom';
import Auth from './components/Auth';
import Main from './components';
import UpcomingEvents from './components/Anon';
// import MasterClassListing from './components/MasterClasses/MasterClassListing';
import IERedirect from './components/Auth/IERedirect';
// import UpcomingEvents from './components/Anon/index';
// import Webcasts from './components/Webcasts';
import SlideMenuContext from './context/SlideMenuContext';
// import BorderButton from './components/common/BorderButton';
import RegisterForm from './components/common/RegisterForm';

import config from './config';

const Router = (props) => {
  const [isSlideMenuOpen, setIsSlideMenuOpen] = useState(false);
  const [regModalOpen, setRegModalOpen] = useState(false);
  const [isSlideMenuContactRepClicked, setIsSlideMenuContactRepClicked] = useState(false);
  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const { store, history, location } = props;
  const { auth } = store.getState();
  const isLoggedIn = auth.user && auth.accessToken;

  // const currentConfigIndex = config.NewsAndViewsVolumeTabConfigs.length - 1;

  const isIE = () => {
    // IE 10 and IE 11
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // This is internet explorer 10
      return true;
    }

    if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
      // This is internet explorer 9 or 11
      return true;
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      return true;
    }
    return false;
  };

  const checkUserAuth = async () => {
    const req = await fetch(`${config.baseApiUrl}/auth/checkuserauth`, {
      headers: auth.headers || {}
    });
    // console.log('req.status', req.status);
    const dimension = { dimension1: req.status === 200 ? 1 : 0 };
    ReactGA.set(dimension);
    console.log('reactGA', window.location.pathname + window.location.search);
    // debugger;
    ReactGA.pageview(window.location.pathname + window.location.search, dimension);
  };

  //  check if ie, if yes, throw to the ie screen, if nnot -- keep it movin there big guy
  const ugh = isIE();
  if (ugh) {
    return <IERedirect />;
  }
  checkUserAuth();

  if (isLoggedIn) {
    Sentry.setUser({ id: auth.user.id, email: auth.user.email });
  }

  // eslint-disable-next-line import/no-named-as-default-member
  const code = config.getUrlParams('code');
  if (code && !isLoggedIn && location.pathname !== '/auth/register') {
    return <Redirect to={`/auth/register${window.location.search}`} />;
  }

  if (!isLoggedIn && location.pathname === '/xmc') {
    return <Redirect to="/anon/xmc" />;
  }

  const match = location.pathname.match(/\/i\/([A-Z0-9]{6})/);
  if (match && match.length > 1) {
    const newPath = `/?code=${match[1]}&utm_source=invite&utm_medium=link&utm_campaign=invite`;
    return <Redirect to={newPath} />;
  }

  // if (location.pathname.startsWith('/epilepsy-perspectives-insights')) {
  //   // redirect to newest volume
  //   const newUrl = `/vol${config.NewsAndViewsVolumeTabConfigs[currentConfigIndex].volNumber}${location.pathname}${window.location.search}`;
  //   return <Redirect to={newUrl} />;
  // }

  const toggleRegModal = () => {
    setRegModalOpen(!regModalOpen);
  };

  const logInHandler = () => {
    setIsSlideMenuOpen(false);
    const logInWidget = document.getElementById('login-widget');
    logInWidget.scrollIntoView();
  };

  const registerHandler = () => {
    setIsSlideMenuOpen(false);
    setRegModalOpen(true);
  };

  const slideMenuItemHandler = () => {
    setIsSlideMenuOpen(false);
    // setIsSubMenuOpen(false);
    const rootEl = document.getElementById('root');
    rootEl.scrollTo(0, 0);
  };

  // const subMenuHandler = () => {
  //   setIsSubMenuOpen(!isSubMenuOpen);
  // };

  const slideMenuContactRepHAndler = (e) => {
    e.preventDefault();
    setIsSlideMenuContactRepClicked(true);
  };

  const isMenuOpen = (state) => {
    console.log('slider open', state.isOpen);
  };

  // const renderSubMenuContent = () => {
  //   // Check url for epilepsy-perspectives-insights
  //   // account for vol3 and vol4

  //   // DEFAULT TO LATEST VOLUME

  //   let selectedConfig = config.NewsAndViewsVolumeTabConfigs[currentConfigIndex];

  //   // if user is not on the current volume or home page, set the one selected
  //   if (location.pathname.includes('epilepsy-perspectives-insights')) {
  //     config.NewsAndViewsVolumeTabConfigs.forEach((volume, key) => {
  //       if (key !== currentConfigIndex && location.pathname.startsWith(`/vol${volume.volNumber}`)) {
  //         selectedConfig = volume;
  //       }
  //     });
  //   }

  //   return selectedConfig.tabs.map((tab, key) => (
  //     <Link
  //       key={key}
  //       className={`sub-menu-items ${isSubMenuOpen ? 'active' : ''}`}
  //       to={`/vol${selectedConfig.volNumber}/epilepsy-perspectives-insights/${tab.key}`}
  //       onClick={slideMenuItemHandler}
  //     >
  //       {tab.customKeyName ? (
  //         <span style={{ textTransform: 'none' }}>{tab.customKeyName}</span>
  //       ) : (
  //         <span>{tab.key.replace(/-/g, ' ')}</span>
  //       )}
  //     </Link>
  //   ));
  // };

  const renderSlideMenu = () => {
    if (isLoggedIn) {
      return (
        <SlideMenu customBurgerIcon={false} isOpen={isSlideMenuOpen} onStateChange={isMenuOpen} width={'100%'}>
          {/* <Link className="menu-item" to="/" onClick={slideMenuItemHandler}>
            Home
          </Link> */}
          <Link
            className="menu-item new-mobile-header"
            to="/"
            style={{ display: 'flex' }}
            onClick={slideMenuItemHandler}
          >
            <span>My Events</span>
            <RightOutlined className="right-outlined-class" />
          </Link>
          <Link className="menu-item" to="/xmc" style={{ display: 'flex' }} onClick={slideMenuItemHandler}>
            <span>XCOPRI masterclass</span>
            <RightOutlined className="right-outlined-class" />
          </Link>
          <Link to="/profile" style={{ display: 'flex' }} onClick={slideMenuItemHandler}>
            <span>My Profile</span>
            <RightOutlined className="right-outlined-class" />
          </Link>
          {/* <div
            onClick={subMenuHandler}
            style={{
              backgroundColor: isSubMenuOpen ? 'rgb(202, 53, 60)' : ''
            }}
            role="button"
            tabIndex={0}
            className="epiMenuOption"
          >
            <span>EPILEPSY PERSPECTIVES & INSIGHTS</span>
            {isSubMenuOpen ? (
              <DownOutlined className="right-outlined-class" />
            ) : (
              <RightOutlined className="right-outlined-class" />
            )}
          </div> */}
          {/* SUBMENU LINKS START */}
          {/* {renderSubMenuContent()} */}
          {/* SUBMENU LINKS END */}
          {/* <Link to="/resources" onClick={slideMenuItemHandler} style={{ display: 'flex' }}>
            <span>Resources</span>
            <RightOutlined className="right-outlined-class" />
          </Link> */}
          <a onClick={slideMenuContactRepHAndler} tabIndex={0} role="button" href="true">
            <span>CONTACT A REPRESENTATIVE</span>
          </a>
        </SlideMenu>
      );
    }
    return (
      <SlideMenu customBurgerIcon={false} isOpen={isSlideMenuOpen} onStateChange={isMenuOpen} width={'100%'}>
        {/* <div
          onClick={subMenuHandler}
          style={{
            backgroundColor: isSubMenuOpen ? 'rgb(202, 53, 60)' : ''
          }}
          role="button"
          tabIndex={0}
          className="epiMenuOption"
        >
          <span>EPILEPSY PERSPECTIVES & INSIGHTS</span>
          {isSubMenuOpen ? (
            <DownOutlined className="right-outlined-class" />
          ) : (
            <RightOutlined className="right-outlined-class" />
          )}
        </div> */}
        {/* SUBMENU LINKS START */}
        {/* {renderSubMenuContent()} */}
        {/* SUBMENU LINKS END */}
        <div onClick={logInHandler} role="button" tabIndex={0} className="epiMenuOption">
          <span>LOG IN</span>
          <RightOutlined className="right-outlined-class" />
        </div>
        <div onClick={registerHandler} role="button" tabIndex={0} className="epiMenuOption">
          <span>REGISTER</span>
          <RightOutlined className="right-outlined-class" />
        </div>
      </SlideMenu>
    );
  };

  return (
    <SlideMenuContext.Provider
      value={{
        isSlideMenuOpen,
        setIsSlideMenuOpen,
        isSlideMenuContactRepClicked,
        setIsSlideMenuContactRepClicked
      }}
    >
      <div
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        className={`${window.location.pathname === '/webcasts' ? 'webcasts' : ''}`}
      >
        {renderSlideMenu()}
        <Modal visible={regModalOpen} onCancel={toggleRegModal} width="75%" footer={null}>
          <RegisterForm toggleRegModal={toggleRegModal} />
        </Modal>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/anon/xmc" component={UpcomingEvents} />
          <Route
            path="/"
            render={() => {
              // // check if this is an anonymous request to the /epi page
              // const isAnonEpiReq = !isLoggedIn && location.pathname.startsWith('/vol');
              // // console.log('isAnonEpiReq', isAnonEpiReq);
              // return isLoggedIn || isAnonEpiReq ? (
              //   <Main isAnonEpiReq={isAnonEpiReq} />
              // ) : (
              //   history.push(`/auth${location.search}`)
              // );
              const redirectParams = location.search
                ? `${location.search}&next=${location.pathname}`
                : `?next=${location.pathname}`;
              return isLoggedIn ? <Main /> : history.push(`/auth${redirectParams}`);
            }}
          />
        </Switch>
      </div>
    </SlideMenuContext.Provider>
  );
};

const RouterComponent = withRouter(Router);

export default process.env.NODE_ENV === 'development' ? hot(RouterComponent) : RouterComponent;
