import React from 'react';
import { connect } from 'react-redux';
// import RegisterForm from './RegisterForm';
import { UserLookupForm } from '../common';
import RegisterForm from '../common/RegisterForm';
import { updateData } from '../../redux/authSlice';
import userLookupFormStyles from '../../Styles/UserLookupForm.module.scss';

// import config from '../../config';

const AuthComponent = (props) => {
  props.updateData({
    userFromLookup: null,
    error: null
  }); // reset user from lookup and set err to null

  return (
    <div>
      <UserLookupForm />
      <div
        className={`flex vertical-center horizontal-center ${userLookupFormStyles.divider}`}
        style={{
          margin: '20px 0'
        }}
      >
        <div className="line" />
      </div>
      <RegisterForm />
    </div>
  );
};

export default connect(null, { updateData })(AuthComponent);
