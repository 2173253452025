/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useRef, useEffect } from 'react';
// import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { XKCMasterclassBar } from '../common';
import SideWidgets from '../common/SideWidgets';
// import {IndicationLine} from '../common';

import AuthHeaderBanner from './AuthHeaderBanner';
import styles from '../../Styles/AuthComponent.module.scss';

// import registerIcon from '../../img/newsAndViews/Register-icon_2x.png';
// import watchIcon from '../../img/newsAndViews/Watch-icon_2x.png';
// import accessIcon from '../../img/newsAndViews/Access-icon_2x.png';
// import newsIcon from '../../img/newsAndViews/EPI_CTA-icon_2x.png';
// import config from '../../config';

// const { Title, Text } = Typography;

const NewAuthLanding = (props) => {
  // const [registerModalOpen, setRegisterModalOpen] = useState(false);
  // const [isNewsAndViews, setIsNewsAndViews] = useState(false);

  // if (isNewsAndViews) {
  //   return <NewsAndViews Archive Login Register />;
  // }
  const myRef = useRef(null);

  // const latestConfig =
  //   config.NewsAndViewsVolumeTabConfigs[config.NewsAndViewsVolumeTabConfigs.length - 1];

  // const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView();
    }
  }, [myRef]);

  // const handleRegisterClick = () => {
  //   console.log('here');
  //   setRegisterModalOpen(!registerModalOpen);
  // };

  return (
    <div style={{ margin: '-50px' }} ref={myRef}>
      <AuthHeaderBanner />
      <div className={styles['auth-wrapper']}>
        <Row justify="space-between">
          <Col xl={16} lg={24} md={24} sm={24}>
            <XKCMasterclassBar extraStyles={{ margin: '20px 0px' }} />
            {/* <h2 className={styles['auth-header']}>About XCOPRI NOW</h2>
            <Row align="middle" style={{ marginBottom: 24 }}>
              <Col style={{ paddingRight: 4 }} xl={3} lg={3} md={3} sm={3} xs={3}>
                <img src={registerIcon} alt="" style={{ width: 50 }} />
              </Col>
              <Col
                xl={21}
                lg={21}
                md={21}
                sm={21}
                xs={21}
                style={{ fontFamily: 'din-2014', color: '#54565b' }}
              >
                <span className={styles['purple-bolded']}>REGISTER</span> for upcoming expert-led
                XCOPRI<sup>&reg;</sup> (cenobamate tablets) CV events
              </Col>
            </Row>
            <Row align="middle" style={{ marginBottom: 24 }}>
              <Col style={{ paddingRight: 4 }} xl={3} lg={3} md={3} sm={3} xs={3}>
                <img src={watchIcon} alt="" style={{ width: 50 }} />
              </Col>
              <Col
                xl={21}
                lg={21}
                md={21}
                sm={21}
                xs={21}
                style={{ fontFamily: 'din-2014', color: '#54565b' }}
              >
                <span className={styles['purple-bolded']}>WATCH</span> informative videos from
                leading experts in epilepsy
              </Col>
            </Row>
            <Row align="middle" style={{ marginBottom: 24 }}>
              <Col style={{ paddingRight: 4 }} xl={3} lg={3} md={3} sm={3} xs={3}>
                <img src={accessIcon} alt="" style={{ width: 50 }} />
              </Col>
              <Col
                xl={21}
                lg={21}
                md={21}
                sm={21}
                xs={21}
                style={{ fontFamily: 'din-2014', color: '#54565b' }}
              >
                <span className={styles['purple-bolded']}>ACCESS</span> resources of real-world
                experiences with XCOPRI
              </Col>
            </Row>
             <h2 className={styles['auth-header']}>
              <p>
                <span>Epilepsy</span> <span>Perspectives</span> & <span>Insights</span>
              </p>
            </h2>
            <Row className={styles.EpiSeriesRow}>
              <Col style={{ paddingRight: 4 }} xl={3} lg={3} md={3} sm={3}>
                <img src={newsIcon} alt="" style={{ width: 50 }} />
              </Col>
              <Col
                xl={18}
                lg={18}
                md={18}
                sm={18}
                flex="1"
                style={{ fontFamily: 'din-2014', color: '#54565b' }}
              >
                A series of educational newsletters exploring various topics in epilepsy, including
                the impact of epilepsy on patients, XCOPRI as a therapeutic option for partial-onset
                seizures in adult patients, epilepsy expert insights, and more!
              </Col>
            </Row>
            <p
              onClick={() => {
                props.history.push(
                  `/vol${latestConfig.volNumber}/epilepsy-perspectives-insights/${latestConfig.tabs[0].key}`
                );
              }}
              role="presentation"
              style={{
                cursor: 'pointer',
                marginTop: 40,
                textTransform: 'uppercase',
                color: '#DC1E34',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Read the latest volume <span className={styles.arrow} />
            </p> */}
          </Col>
          <Col xl={6} lg={18} md={24} sm={24} xs={24} align="middle">
            <SideWidgets
              Login
              Register
              // registerModalOpen={registerModalOpen}
              // setRegisterModalOpen={setRegisterModalOpen}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(NewAuthLanding);
