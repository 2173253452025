import React from 'react';
import { Typography } from 'antd';
import { withRouter } from 'react-router-dom';
// import XKCPatientBar from './XKCPatientBar';

const { Title, Text } = Typography;

const XKCPolicy = (props) => {
  // const handleLinkNavigation = (link) => {
  //   return () => {
  //     props.history.push(link);
  //     const rootEl = document.getElementById('root');
  //     rootEl.scrollTo(0, 0);
  //   };
  // };

  return (
    <div>
      <div style={{ fontFamily: 'Arial', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            textAlign: 'left',
            alignSelf: 'flex-start',
            lineHeight: 1.3,
            margin: '0 50px 25px 50px',
            backgroundColor: '#fff'
          }}
          className="policy-bar"
        >
          {!props.isMasterClass && (
            <>
              <Title level={4}>SK Life Science, Inc. Policy</Title>
              <Text>
                Individuals may attend more than one Speaker Program in a 12-month period on a
                topic, but they may not receive a meal at more than one program.
              </Text>
            </>
          )}
        </div>
      </div>
      {/* <XKCPatientBar /> */}
    </div>
  );
};

export default withRouter(XKCPolicy);
