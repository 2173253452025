import React, { useContext, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Modal, Menu } from 'antd';
import { Squash as Hamburger } from 'hamburger-react';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';
import SlideMenuContext from '../../context/SlideMenuContext';
// import { LogoutOutlined } from '@ant-design/icons';
import styles from '../../Styles/XKCHeader.module.scss';
import config from '../../config';
import xkcLogo from '../../img/header-logo.png';
// eslint-disable-next-line import/no-cycle
import { BorderButton } from '.';
// const xkcLogoSimple = require('../../img/header-logo-simple.png');
const { Header } = Layout;

// const mkcLogo = require('../img/logo-official.svg');

const XKCHeader = (props) => {
  const [modal, modalContext] = Modal.useModal();
  const [isOrangeCalloutActive, setIsOrangeCalloutActive] = React.useState(false);
  const [isOrangeCalloutClosed, setIsOrangeCalloutClosed] = React.useState(false);
  // const latestKey =
  //   config.NewsAndViewsVolumeTabConfigs[config.NewsAndViewsVolumeTabConfigs.length - 1];
  // const [activeKey, setActiveKey] = React.useState(
  //   `/vol${latestKey.volNumber}/epilepsy-perspectives-insights/${latestKey.tabs[0].key}`
  // );
  const modalRef = useRef(null);
  const {
    isSlideMenuOpen,
    setIsSlideMenuOpen,
    isSlideMenuContactRepClicked,
    setIsSlideMenuContactRepClicked
  } = useContext(SlideMenuContext);

  useEffect(() => {
    if (isSlideMenuContactRepClicked) {
      menuOnClick({ key: 'contactSpecialist' });
      setIsSlideMenuContactRepClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSlideMenuContactRepClicked]);

  useEffect(() => {
    const header = document.getElementById('itc-header');
    const mobileNav = document.querySelector('.bm-menu-wrap');
    mobileNav.style.top = `${header.offsetHeight}px`;
    const headerHeight = header.offsetHeight;
    document.body.style.paddingTop = `${headerHeight}px`;
  }, [isOrangeCalloutActive]);

  // console.log(isSlideMenuOpen, 'slide menu');
  useEffect(() => {
    if (props.isAuth || props.isAnon || props.isVirtualWebcasts) {
      if (props.location.pathname.includes('epilepsy-perspectives-insights')) {
        setIsOrangeCalloutActive(true);
      }
    }

    const headerSizing = () => {
      const header = document.getElementById('itc-header');
      const mobileNav = document.querySelector('.bm-menu-wrap');
      mobileNav.style.top = `${header.offsetHeight}px`;
      const headerHeight = header.offsetHeight;
      document.body.style.paddingTop = `${headerHeight}px`;
    };

    window.addEventListener('load', headerSizing);
    window.addEventListener('resize', headerSizing);
    return () => {
      // remove resize listener
      window.removeEventListener('load', headerSizing);
      window.removeEventListener('resize', headerSizing);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (props.location.pathname.includes('epilepsy-perspectives-insights')) {
  //     setActiveKey(props.location.pathname);
  //   }
  // }, [props.location.pathname]);

  const samplesOrContactRequest = (requestType) => {
    config.fetch(`${config.baseApiUrl}/api/${requestType}`, {
      method: 'POST',
      headers: props.auth.headers,
      body: JSON.stringify({ external_id: props.auth.user.external_id })
    });
  };

  const renderModalText = (action) => {
    switch (action) {
      case 'contactSpecialist':
        samplesOrContactRequest('contactrep');
        ReactGA.event({
          category: 'Contact A Representative',
          action: 'Contact A Representative'
        });
        return (
          <>
            Thank You. Your request has been received.
            <br />A sales specialist will be in contact with you soon.
          </>
        );
      default:
        return '';
    }
  };

  // console.log(styles);

  const closeModal = () => {
    modalRef.current.destroy();
  };

  const menuOnClick = ({ key }) => {
    if (key !== 'burger') {
      props.scrollToTop();
    } else {
      setIsSlideMenuOpen(!isSlideMenuOpen);
    }
    if (key === 'contactSpecialist') {
      modalRef.current = modal.info({
        content: (
          <BorderButton wrapperStyle={{ margin: '0 auto' }} onClick={() => closeModal()}>
            CLOSE
          </BorderButton>
        ),
        title: (
          <div
            className="flex purple"
            style={{
              justifyContent: 'center',
              fontWeight: 800,
              fontSize: 32
            }}
          >
            <div className="purple">{renderModalText(key)}</div>
          </div>
        ),
        icon: null,
        maskClosable: true,
        okText: 'CLOSE',
        centered: true,
        className: 'contactSpecialistModal',
        okButtonProps: {
          style: {
            justifyContent: 'center',
            width: 300,
            fontSize: 16,
            fontWeight: 600,
            borderRadius: '16px'
          }
        }
      });
    } else if (key === 'samplesRequest') {
      ReactGA.event({
        category: 'Request Samples',
        action: 'Request Samples'
      });
    } else if (key === 'prescribingInfo') {
      ReactGA.event({
        category: 'Prescribing Information',
        action: 'Prescribing Information'
      });
    } else if (key !== 'burger') {
      props.history.push(key);
    }

    // if (key.includes('epilepsy-perspectives-insights')) {
    //   const lastKey = config.volumeTabConfigs[config.volumeTabConfigs.length - 1];
    //   const path = `/vol${lastKey.volNumber}/epilepsy-perspectives-insights/${lastKey.tabs[0].key}`;

    //   setActiveKey(path);
    //   props.history.push(path);
    // }
  };

  const renderLogo = (logoClasses) => {
    return (
      <div style={{ position: 'relative' }}>
        <img
          className={`${styles.logo} ${logoClasses || ''}`}
          alt="logo"
          src={xkcLogo}
          onClick={() => {
            props.history.push('/');
          }}
          role="presentation"
        />
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '53%',
            top: 0,
            right: '23px',
            cursor: 'pointer'
          }}
          onClick={() => {
            window.open('https://xcoprihcp.com/', '_blank');
          }}
          role="presentation"
        />
      </div>
    );
  };

  const renderHeaderContent = () => {
    if (props.isAuth || props.isAnon || props.isVirtualWebcasts) {
      return (
        <>
          <div
            className="noAuthTopHeader"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            {renderLogo('headerLogoNoAuth')}
            {!props.isAuth && !props.isAnon ? (
              <Hamburger
                toggled={isSlideMenuOpen}
                toggle={setIsSlideMenuOpen}
                size={isMobile ? 32 : 48}
                duration={0.3}
                color="#5B2C86"
                easing="ease-in"
                rounded
              />
            ) : null}
            <span
              className={`${styles['header-disclaimer']} noAuthDisclaimer`}
              style={{
                color: '#54565B',
                fontSize: 20,
                marginRight: 30,
                whiteSpace: 'nowrap',
                alignSelf: 'center',
                fontFamily: 'din-2014-narrow'
              }}
            >
              This site is for US healthcare professionals only.
            </span>
          </div>
        </>
      );
    }

    return (
      <>
        {renderLogo()}
        <Menu
          // inlineCollapsed={false}
          key={'menu'}
          theme="light"
          mode="horizontal"
          selectedKeys={[props.location.pathname]}
          onClick={menuOnClick}
          disabledOverflow
          style={{ fontSize: 17 }}
        >
          {/* <Menu.Item
            className={`${styles['hide-desktop']} header-active-underline header-item`}
            key="/"
          >
            <span id="Home_Nav_" text="Home" style={{ position: 'relative' }}>
              Home
              <span className="header-active-underline-span" />
            </span>
          </Menu.Item> */}
          <Menu.Item
            className={`${styles['hide-desktop']} header-active-underline header-item`}
            key="/"
          >
            <span id="My_Events_Nav_" text="My Events" style={{ position: 'relative' }}>
              My Events
              <span className="header-active-underline-span" />
            </span>
          </Menu.Item>

          <Menu.Item
            className={`${styles['hide-desktop']} header-active-underline header-item`}
            key="/xmc"
          >
            <span
              id="Xcopri_Masters_Nav_"
              text="XCOPRI masterclass"
              style={{ position: 'relative' }}
            >
              XCOPRI masterclass
              <span className="header-active-underline-span" />
            </span>
          </Menu.Item>
          <Menu.Item
            className={`${styles['hide-desktop']} header-active-underline header-item`}
            key="/profile"
          >
            <span id="My_Profile_Nav_" text="My Profile" style={{ position: 'relative' }}>
              My Profile
              <span className="header-active-underline-span" />
            </span>
          </Menu.Item>
          {/* <Menu.Item
            className={`${styles['hide-desktop']} header-active-underline header-item`}
            key={activeKey}
          >
            <span
              id="Epilepsy_Perspectives_Insights"
              text="Epilepsy Perspectives & Insights"
              style={{ position: 'relative' }}
            >
              Epilepsy Perspectives & Insights
              <span className="header-active-underline-span" />
            </span>
          </Menu.Item> */}
          {/* <Menu.Item
            className={`${styles['hide-desktop']} header-active-underline header-item`}
            key="/resources"
          >
            <span id="Resources_Nav_" text="Resources" style={{ position: 'relative' }}>
              Resources
              <span className="header-active-underline-span" />
            </span>
          </Menu.Item> */}
          <Menu.Item key="contactSpecialist" className={`${styles['hide-desktop']} header-item`}>
            <span
              id="Contact_A_Representative_Nav_"
              text="Contact a Representative"
              className={`${styles['btn__solid--green']}`}
            >
              Contact a Representative
            </span>
          </Menu.Item>
          <Menu.Item key="burger" className={`${styles.burgerbars} header-item `}>
            <Hamburger
              toggled={isSlideMenuOpen}
              toggle={setIsSlideMenuOpen}
              size={isMobile ? 32 : 48}
              duration={0.3}
              color="#5B2C86"
              easing="ease-in"
              rounded
            />
          </Menu.Item>
        </Menu>
        {modalContext}
      </>
    );
  };

  const closeOrangeCallout = () => {
    setIsOrangeCalloutClosed(true);
    setIsOrangeCalloutActive(false);
  };

  const renderOrangeCallout = () => {
    if ((props.isAuth || props.isAnon || props.isVirtualWebcasts) && !isOrangeCalloutClosed) {
      if (props.location.pathname.includes('epilepsy-perspectives-insights')) {
        return (
          <div className="orangeCallout">
            <div style={{ flex: 1, padding: 20 }}>
              <span style={{ fontFamily: 'din-2014-narrow', fontWeight: 'bold' }}>
                XCOPRI NOW is a platform with resources for expert-led XCOPRI events, informative
                videos, and more.
              </span>
            </div>
            <div
              style={{ padding: '16px 16px 0 0', fontSize: 26, cursor: 'pointer' }}
              onClick={closeOrangeCallout}
              role="button"
              tabIndex={0}
            >
              X
            </div>
          </div>
        );
      }
    }
    return null;
  };

  // FLEX DIRECTION CHANGE: If logged in row, not column

  return (
    <>
      <Header
        id="itc-header"
        className={`${styles.header} ${styles['ant-layout-header']} ${
          (props.isAuth || props.isAnon || props.isVirtualWebcasts) &&
          styles['ant-layout-header-noauth']
        }`}
        style={{
          position: 'fixed',
          width: '100%',
          display: 'flex',
          flexDirection: `${
            (props.isAuth || props.isAnon || props.isVirtualWebcasts) &&
            props.location.pathname.includes('epilepsy-perspectives-insights')
              ? 'column'
              : 'row'
          }`,
          alignItems: 'center',
          fontFamily: 'Arial'
        }}
      >
        {renderOrangeCallout()}
        {renderHeaderContent()}
      </Header>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default withRouter(connect(mapStateToProps, null)(XKCHeader));
