/* eslint-disable import/no-extraneous-dependencies */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';
import { v4 as uuidv4 } from 'uuid';
// import Analytics from 'react-router-ga';
import 'tailwindcss/tailwind.css';
import { store, persistor } from './store';

import * as serviceWorker from './serviceWorker';

// import Main from './components';
import Router from './Router';

import './Styles/App.scss';
// no idea why this is here tbh and why its not in App.scss.
// May have been used mainkly for news and views?!
import './Styles/standard.css'; // DO NOT USE THIS FOR ANYTHING NEW!!!!!

const isProd = process.env.NODE_ENV === 'production';

let GAMeasurementID = 'G-TFPDPG033K'; // beta
if (window.location.hostname === 'www.xcoprinow.com') {
  GAMeasurementID = 'G-TFPDPG033K';
}

ReactGA.initialize(GAMeasurementID);

const sessionID = sessionStorage.getItem('sessionID');
if (!sessionID) {
  sessionStorage.setItem('sessionID', uuidv4().replace(/\W/g, ''));
}

if (isProd) {
  Sentry.init({
    dsn: 'https://97456e0cdffd4847a216877915263fa2@o124700.ingest.sentry.io/4504368349904896',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <Router store={store} />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
