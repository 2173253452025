import React from 'react';
import { Typography, Divider } from 'antd';
const { Title } = Typography;

const Styles = {
  dividerStyle: {
    margin: '10px 0',
    marginTop: 10,
    marginBottom: 10,
    color: '#54565b'
  }
};

const ObjectivesAndWarnings = (props) => {
  const { objective, is_broadcast } = props.session;

  if (!objective) return null;

  return (
    <div style={{ color: '#8F9291' }}>
      <Title
        level={5}
        style={{ fontWeight: 'bold', color: '#54565b' }}
        className={'program-objectives'}
      >
        Program Objectives
      </Title>
      <span dangerouslySetInnerHTML={{ __html: objective }} />
      {!is_broadcast && <Divider style={Styles.dividerStyle} />}
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.session.id === nextProps.session.id;
};

export default React.memo(ObjectivesAndWarnings, areEqual);
