import React, { useEffect, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Typography, Tabs, Row, Col } from 'antd';
import _ from 'lodash';
import styles from '../../../Styles/NewsAndViews.module.scss';
import Header from '../Header';
// import authStyles from '../../../../Styles/AuthComponent.module.scss';

import SideWidgets from '../SideWidgets';
import IndicationLine from '../IndicationLine';

const { Title } = Typography;
const { TabPane } = Tabs;

const NewsAndViews = (props) => {
  const { Login, Register, volumeConfig, history } = props;

  const [activeKey, setActiveKey] = React.useState(volumeConfig.tabs[0].key);
  const [registerModalOpen, setRegisterModalOpen] = React.useState(false);

  // const handleRegisterClick = () => {
  //   setRegisterModalOpen(!registerModalOpen);
  // };

  const sideWidgetsRef = useRef(null);
  // const executeScroll = () => sideWidgetsRef.current.scrollIntoView();

  const onKeyChange = (key) => {
    setActiveKey(key);
    console.log('KEY CHANGE', key);
    history.push(`/vol${volumeConfig.volNumber}/epilepsy-perspectives-insights/${key}`);
  };

  const navigateNext = () => {
    const index = _.findIndex(volumeConfig.tabs, { key: activeKey });
    const newTab = volumeConfig.tabs[index + 1].key;
    setActiveKey(newTab);
    history.push(`/vol${volumeConfig.volNumber}/epilepsy-perspectives-insights/${newTab}`);
    const rootEl = document.getElementById('root');
    rootEl.scrollTo(0, 0);
  };

  const { tab } = useParams();

  useEffect(() => {
    if (tab !== undefined) {
      setActiveKey(tab);
    }
  }, [tab]);

  const Styles = {
    tabWrapper: {
      backgroundColor: 'rgb(168, 186, 200)',
      color: 'white',
      fontFamily: 'din-2014-narrow'
    }
  };

  return (
    <div>
      <Header
        title={() => (
          <div className="header-text">
            <Title className="text__white" style={{ marginBottom: 0, fontWeight: '400' }} level={4}>
              <span className="text__white epi-header" style={{ fontFamily: 'din-2014' }}>
                <span style={{ fontFamily: 'din-2014' }}>Epilepsy</span>{' '}
                <span style={{ fontFamily: 'din-2014' }}>Perspectives</span> &{' '}
                <span style={{ fontFamily: 'din-2014' }}>Insights</span>{' '}
              </span>
              |{' '}
              <span style={{ fontSize: 12, fontFamily: 'din-2014' }}>
                {volumeConfig.headerDate}
              </span>
            </Title>
            <Title
              className="text__white"
              style={{
                marginBottom: 0,
                marginTop: 0,
                lineHeight: 1.1,
                fontFamily: 'din-2014'
              }}
              level={3}
            >
              {volumeConfig.headerTop}
              <br />
              {volumeConfig.headerBottom}
            </Title>
            <IndicationLine />
          </div>
        )}
      />
      {/* {Login && (
        <div className={authStyles.mobileTopMenu}>
          <div
            className={`${authStyles.topMenuItem} ${authStyles.topMenuItemLeft}`}
            onClick={executeScroll}
            role="button"
            tabIndex={0}
          >
            LOGIN
          </div>
          <div
            className={`${authStyles.topMenuItem} ${authStyles.topMenuItemRight}`}
            onClick={() => handleRegisterClick()}
            role="button"
            tabIndex={0}
          >
            REGISTER
          </div>
        </div>
      )} */}
      {/* GREY HEADER */}
      {/* <div className={`mobileActiveTab ${!Login ? 'mobileActiveTabLoggedIn' : ''}`}>
        {activeKey.replace('-', ' ').toUpperCase()}
      </div> */}
      <Tabs
        defaultActiveKey={_.first(volumeConfig.tabs.key)}
        centered
        activeKey={activeKey}
        onChange={onKeyChange}
        tabBarStyle={Styles.tabWrapper}
        className="epiTabs"
        destroyInactiveTabPane
      >
        {volumeConfig.tabs.map((Tab) => {
          const refProp = Tab.key === activeKey ? { ref: sideWidgetsRef } : {};
          return (
            <TabPane tab={Tab.component.tabTitle} key={Tab.key}>
              <Row justify="space-between">
                <Col xl={18} lg={18} md={24} sm={24} className={styles['right-content-padding']}>
                  {Tab.component.tabContent}
                  {_.findIndex(volumeConfig.tabs, { key: activeKey }) <
                    volumeConfig.tabs.length - 1 && (
                    <h3
                      onClick={() => navigateNext()}
                      role="presentation"
                      className={styles['next-article']}
                    >
                      NEXT ARTICLE <span className={styles.arrow} />
                    </h3>
                  )}
                </Col>
                <Col
                  xl={6}
                  lg={5}
                  md={24}
                  sm={24}
                  align="middle"
                  className={styles['right-content-padding']}
                  {...refProp}
                >
                  <SideWidgets
                    newsViewsVolumeNumber={volumeConfig.volNumber}
                    Login={Login}
                    Register={Register}
                    registerModalOpen={registerModalOpen}
                    setRegisterModalOpen={setRegisterModalOpen}
                  />
                </Col>
              </Row>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default withRouter(NewsAndViews);
