import React from 'react';
import ReactGA from 'react-ga';
import { Modal, Checkbox, Typography } from 'antd';
import BorderButton from './BorderButton';

const { Title, Text } = Typography;

const masterClassGtagIds = {
  2446: 'When_to_Consider_Klein_9.13.23',
  2447: 'Getting_Started_Xcopri_Stern_9.19.23',
  2448: 'Real_World_Results_Koubeissi_9.28.23',
  2449: 'When_to_Consider_Klein_10.12.23',
  2450: 'Getting_Started_Xcopri_Elder_10.25.23',
  2451: 'Real_World_Results_Smith_11.9.23'
};

const RegisterConfirmationModal = (props) => {
  const { open, session, stateAction, onClose, onSuccess, modalType } = props;

  const [checkBoxChecked, setCheckBoxChecked] = React.useState(false);
  const [attendanceType, setAttendanceType] = React.useState(null);

  const handleModalClose = () => {
    onClose();
    ReactGA.event({
      category: 'RegisterConfirmationModal',
      action: `Close: ${session.code}`
    });
  };

  const handleConfirm = (addtionalPayload) => {
    onSuccess(addtionalPayload);
    setCheckBoxChecked(false);
    stateAction(false);
    ReactGA.event({
      category: 'RegisterConfirmationModal',
      action: `Confirm: ${session.code}`
    });
  };

  const renderVirtualHybridCheckboxes = () => {
    if (session.is_virtual_hybrid) {
      return (
        <div style={{ marginTop: 15 }}>
          <Text>
            The program you are registering for is hybrid and can accomodate both in -person and virtual attendees.
            Please confirm how you are planning to attend by choosing from the options below.
          </Text>
          <div className="flex horizontal-center" style={{ marginTop: 15 }}>
            <Checkbox
              onChange={() => {
                setAttendanceType('in-person');
              }}
              // style={{ color: 'gray', textAlign: 'left', width: '90%', marginTop: 20 }}
              // value="in-person"
              checked={attendanceType === 'in-person'}
            >
              In Person
            </Checkbox>
            <Checkbox
              onChange={() => {
                setAttendanceType('virtual');
              }}
              // style={{ color: 'gray', textAlign: 'left', width: '90%', marginTop: 20 }}
              // value="virtual"
              checked={attendanceType === 'virtual'}
            >
              Virtual
            </Checkbox>
          </div>
        </div>
      );
    }
  };

  const renderModalContent = () => {
    // console.log('REgisterConfirmationModal', modalType);
    // console.log('checkboxcheced', checkBoxChecked);
    if (!modalType || modalType === 'nonAffiliation') {
      return (
        <>
          <Title style={{ color: '#5B2C86', fontSize: 22 }}>
            You&apos;re about to register for <br />
            {session.title}
          </Title>
          <Text style={{ color: '#54565b' }}>
            Before you can complete registration,
            <br />
            please confirm by marking the checkbox below.
          </Text>
          {renderVirtualHybridCheckboxes()}

          <Checkbox
            checked={checkBoxChecked}
            onChange={() => {
              setCheckBoxChecked(!checkBoxChecked);
            }}
            style={{ color: '#54565b', textAlign: 'left', width: '90%', marginTop: 20 }}
          >
            I certify that I am not an employee of the speaker&apos;s own medical practice (same physical location) or a
            staff member of a facility for which the speaker is a medical director (whose job function is to educate
            those within her or his supervisory authority).
          </Checkbox>
          <div
            style={{
              display: 'flex',
              margin: 10,
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <BorderButton
              text={'Cancel Registration'}
              onClick={handleModalClose}
              buttonStyle={{ background: '#FFFFFF', color: '#5B2C86 !important', width: 'unset' }}
              wrapperClasses={'register_modal_cancel'}
              wrapperStyle={{
                color: '#5B2C86',
                margin: '4px 2px',
                width: 245
              }}
            />
            <BorderButton
              text={'Confirm'}
              disabled={!checkBoxChecked || (session.is_virtual_hybrid && !attendanceType)}
              onClick={() => handleConfirm({ attendanceType })}
              wrapperStyle={{ width: 245, marginBottom: 8, margin: '4px 2px' }}
              gtagtext={{ text: 'Confirm' }}
              gtagid={{ id: masterClassGtagIds[session.id] || '' }}
            />
          </div>
        </>
      );
    }
    if (modalType === 'mealIneligibility') {
      return (
        <>
          <Title style={{ color: '#5B2C86', fontSize: 22, marginBottom: 30 }}>
            Please note that you have already attended an event on this topic and therefore are not eligible for a meal.
            By registering, you agree not to accept a meal.
          </Title>
          <BorderButton
            text={'Register Now'}
            onClick={() => handleConfirm({ confirmed_meal_ineligibility: true })}
            wrapperStyle={{ maxWidth: 200, margin: 'auto', marginBottom: 8 }}
          />
        </>
      );
    }
  };

  return (
    <Modal
      key={'regconfirmmodal'}
      visible={open}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      destroyOnClose
      footer={null}
      centered
      // width={'80%'}
      width={600}
      style={{ padding: 'auto', top: 10, textAlign: 'center' }}
      className={'register_confirm_modal'}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default RegisterConfirmationModal;
