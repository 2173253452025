import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import config from '../../config';
import { loginUserSuccess } from '../../redux/authSlice';

const AuthenticateUser = (props) => {
  fetch(`${config.baseApiUrl}/auth/authenticate`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(props.match.params)
  }).then((response) => {
    if (response.status !== 200) {
      return props.history.push(`/auth${props.location.search}`);
    }
    response.json().then((responseData) => {
      props.loginUserSuccess({
        accessToken: responseData.access,
        refreshToken: responseData.refresh,
        user: responseData.user,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${responseData.access}`
        }
      });
      props.history.push(`/${props.location.search}`);
    });
  });

  return (
    <div>
      <Spin size="large" indicator={<LoadingOutlined style={{ color: '#624B78' }} spinning />} />
    </div>
  );
};

export default connect(null, { loginUserSuccess })(AuthenticateUser);
