/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

// import { useHistory } from 'react-router-dom';

import _ from 'lodash';
// // import ReactGA from 'react-ga';
import { Typography, Form, Select, Checkbox, Input, Row, Col, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import config from '../../config';
import '../../Styles/PatientCaseForm.scss';

import patientCaseHeaderImg from '../../img/patient-case-header.png';

const { Title } = Typography;

const requiredRule = {
  required: true,
  message: 'This field is required!'
};

// the labels for the 2 checkbox groups
// do not remove the first empty string, it is used for the label of the group
const checkboxGroups = [
  [
    '',
    'LEV (levetiracetam)',
    'TPM (topiramate)',
    'LTG (lamotrigine)',
    'OXC (oxcarbazepine)',
    'CBZ (carbamazepine)',
    'PHT (phenytoin)'
  ],
  ['', 'LCM (lacosamide)', 'PER (perampanel)', 'BRV (brivaracetam)', 'CLB (clobazam)', 'ESL (eslicarbazepine)']
];

const PatientCaseFormModal = (props) => {
  const [form] = Form.useForm();

  const { headers, modalOpen, toggleModalOpen, meetingId } = props;

  const patientCaseFormRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const [justSubmitted, setJustSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const [checkBoxError, setCheckBoxError] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  const surveyName = 'PatientCase';

  useEffect(() => {
    // eslint-disable-next-line import/no-named-as-default-member

    // if (meeting_id) {
    if (modalOpen) {
      config
        .fetch(`${config.baseApiUrl}/api/patient-case?surveyname=${surveyName}&meeting_id=${meetingId}`, {
          method: 'GET',
          headers: headers
        })
        .then((res) => {
          // console.log('res', res);
          if (res.status === 200) {
            setLoaded(true);
          } else {
            setLoaded(true);
            setAlreadySubmitted(true);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });

      if (patientCaseFormRef.current) {
        patientCaseFormRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const closeModal = () => {
    // if (!alreadySubmitted || !justSubmitted) {
    //   // send a request to send out the email if the user closes the modal without submitting

    //   config
    //     .fetch(`${config.baseApiUrl}/api/patient-case`, {
    //       method: 'PUT',
    //       headers: headers,
    //       body: JSON.stringify({
    //         meeting_id: meetingId
    //       })
    //     })
    //     .then((res) => {
    //       // console.log('res', res);
    //     })
    //     .catch((err) => {
    //       console.log('err', err);
    //     });
    // }

    setLoaded(false);
    setError(false);
    setCheckBoxError(false);
    setAlreadySubmitted(false);
    setJustSubmitted(false);
    form.resetFields();
    toggleModalOpen();
  };

  const onFormSubmit = (values) => {
    form
      .validateFields()
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        const formValues = form.getFieldsValue();
        // filter out all keys with no value
        const cleanFormValues = Object.keys(formValues).reduce((acc, key) => {
          const answerValue = formValues[key];
          if (
            // if the value is not undefined, null, or an empty string
            (!_.isArray(answerValue) &&
              formValues[key] !== undefined &&
              formValues[key] !== null &&
              formValues[key] !== '') ||
            // if the value is an array and has a length greater than 0
            (_.isArray(answerValue) && answerValue.length > 0)
          ) {
            acc[key] = _.isArray(answerValue) ? answerValue.join(', ') : answerValue;
          }
          return acc;
        }, {});
        console.log('cleanFormValues', cleanFormValues);

        const answerKeys = Object.keys(cleanFormValues);

        if (answerKeys.length === 0) {
          return;
        }

        // flatten the checkboxGroups array and filter out any empty strings
        const checkboxGroupKeys = _.filter(_.flatten(checkboxGroups), _.identity);
        // get the keys of the checkboxes that are checked
        const checkedCheckboxKeys = _.intersection(answerKeys, checkboxGroupKeys);
        console.log('checkedCheckboxKeys', checkedCheckboxKeys);

        if (checkedCheckboxKeys.length === 0) {
          setCheckBoxError(true);
          return;
        }

        const data = {
          surveyname: surveyName,
          responseguid: Date.now(),
          meeting_id: meetingId,
          responses: answerKeys.map((key) => ({ surveyquestion: key, surveyanswer: cleanFormValues[key] }))
        };

        setSubmitting(true);
        config
          .fetch(`${config.baseApiUrl}/api/patient-case`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
          })
          .then((res) => {
            // console.log('res', res);
            if (res.status === 200) {
              setJustSubmitted(true);
            } else if (res.status === 403) {
              setLoaded(true);
              setAlreadySubmitted(true);
            } else {
              setError(true);
            }
            setSubmitting(false);
          })
          .catch((err) => {
            console.log('err', err);
            setError(true);
            setSubmitting(false);
          });
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  // const renderSubmittedModalText = () => {
  //   if (justSubmitted) {
  //     return 'Thank you for submitting your patient case!';
  //   }
  //   return 'You have already submitted a patient case for this meeting.';
  // };

  const renderPatientCaseForm = () => {
    if (!loaded) {
      return (
        <div className="flex horizontal-center vertical-center" style={{ height: 200 }}>
          <Spin size="large" indicator={<LoadingOutlined style={{ color: '#624B78' }} spinning />} />
        </div>
      );
    }

    if (alreadySubmitted || justSubmitted) {
      console.log('alreadySubmitted', alreadySubmitted, 'justSubmitted', justSubmitted);
      return (
        <div className="flex flex-column horizontal-center vertical-center" style={{ textAlign: 'center' }}>
          <Title level={4} style={{ fontSize: 26 }}>
            {justSubmitted
              ? 'Thank you for submitting your patient case!'
              : 'You have already submitted a patient case for this meeting.'}
          </Title>
          <br />
          <div className="submit-button" style={{ width: 200 }} role="button" onClick={closeModal} tabIndex={0}>
            Close
          </div>
        </div>
      );
    }

    // const onValuesChange = (changedValues, allValues) => {
    //   console.log('changedValues', changedValues);
    //   console.log('allValues', allValues);
    // };

    return (
      <div className="patient-case-content">
        <Form
          requiredMark={false}
          name="patient-case"
          // onValuesChange={onValuesChange}
          onFinish={onFormSubmit}
          form={form}
          layout="vertical"
          // componentSize={'large'}
        >
          <Row justify="space-between">
            <Col xs={24} md={11}>
              <Form.Item name="age" label={'AGE'} rules={[requiredRule]}>
                <Input maxLength={2} type="number" min={0} />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item name="yearsSeizing" label={'YEARS SEIZING'} rules={[requiredRule]}>
                <Input maxLength={2} type="number" min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col xs={24} md={11}>
              <Form.Item name="seizureNumber" label={'NUMBER OF SEIZURES PER MONTH'} rules={[requiredRule]}>
                <Input maxLength={10} type="number" min={0} />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item name="sex" label={'SEX'} rules={[requiredRule]}>
                <Select>
                  <Select.Option value="M" key="M">
                    Male
                  </Select.Option>
                  <Select.Option value="F" key="F">
                    Female
                  </Select.Option>
                  <Select.Option value="U" key="U">
                    Unspecified
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={11}>
              <Form.Item name="occupation" label={'OCCUPATION'} rules={[requiredRule]}>
                <Input maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item name="notes" label={'ADDITIONAL NOTES'}>
                <Input.TextArea rows={5} maxLength={499} />
              </Form.Item>
            </Col>
          </Row>
          <p className="purple-label">CURRENT ANTISEIZURE MEDICATIONS</p>{' '}
          {checkBoxError && <span style={{ color: 'red' }}>Please select at least one medication!</span>}
          <div className="checkbox-wrapper">
            <div className="checkbox-container">
              <div className="checkbox-col">
                {checkboxGroups[0].map((item, idx) => {
                  return (
                    <div className="checkbox-row-container" key={idx}>
                      <span style={{ minWidth: 180 }}>{item}</span>
                      <Form.Item
                        name={item}
                        className={`${idx === 0 ? 'multiAnswerLabel' : 'multiAnswer'}`}
                        // rules={[idx > 0 && requiredRule]}
                      >
                        <Checkbox.Group>
                          {['QD', 'BID', 'Other'].map((subItem, subIdx) => {
                            return (
                              <Checkbox key={subIdx} value={subItem} disabled={idx === 0}>
                                {subItem}
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
              <div className="checkbox-col">
                {checkboxGroups[1].map((item, idx) => {
                  return (
                    <div className="checkbox-row-container" key={idx}>
                      <span style={{ minWidth: 180 }}>{item}</span>
                      <Form.Item
                        name={item}
                        className={`${idx === 0 ? 'multiAnswerLabel' : 'multiAnswer'}`}
                        // rules={[idx > 0 && requiredRule]}
                      >
                        <Checkbox.Group>
                          {['QD', 'BID', 'Other'].map((subItem, subIdx) => {
                            return (
                              <Checkbox key={subIdx} value={subItem} disabled={idx === 0}>
                                {subItem}
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>
            <Form.Item name="other_medications" label={'OTHER MEDICATIONS'} style={{ padding: '0 30px' }}>
              <Input.TextArea rows={5} maxLength={499} />
            </Form.Item>
          </div>
          {error && (
            <p style={{ color: 'red', textAlign: 'center', fontSize: '1rem' }} className="error">
              Something went wrong... please try again later!
            </p>
          )}
          <div className="flex horizontal-center vertical-center" style={{ width: '100%' }}>
            {submitting ? (
              <Spin size="large" indicator={<LoadingOutlined style={{ color: '#624B78', marginTop: 20 }} spinning />} />
            ) : (
              <div className="submit-button" role="button" onClick={onFormSubmit} tabIndex={0}>
                Submit
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  };

  return (
    <Modal
      visible={modalOpen}
      onCancel={closeModal}
      onOk={closeModal}
      destroyOnClose
      footer={null}
      // centered
      className="patient-case-modal"
    >
      <div style={{ position: 'relative', top: -30 }} ref={patientCaseFormRef}>
        <img src={patientCaseHeaderImg} alt="Patient Case Header" style={{ width: '100%', height: 'auto' }} />
      </div>
      <div className="patient-case-title-box">
        <Title level={3} className="patient-case-form-title">
          Patient Case Submission
        </Title>
        <p>
          As part of your participation in the clinical-based roundtable discussions, each attendee is required to
          submit a brief overview of a deidentified patient in their practice who has uncontrolled seizures. Patient
          cases are a core component of the peer-to-peer clinical-based discussions at these programs.
        </p>
      </div>

      {renderPatientCaseForm()}
      <p style={{ position: 'relative', bottom: -20, fontSize: 18 }} ref={patientCaseFormRef}>
        PM-US-XCOP-1454 June 2024
      </p>
    </Modal>
    // </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { headers } = auth;
  return { headers };
};
export default connect(mapStateToProps, null)(PatientCaseFormModal);
