import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';
// import { getUrlParams } from '../../config';
// import SessionComponent from './SessionComponent';
import AuthComponent from './AuthComponent';
import NewAuthLanding from './NewAuthLanding';
import NpiAuthComponent from './NpiAuthComponent';
import Logout from './LogOut';
import { Isi, XKCHeader, XKCFooter } from '../common';
// import GreenBox from '../common/GreenBox';

// import RegisterForm from './RegisterForm';
// import ResetPassword from './ResetPassword';
import AuthenticateUser from './AuthenticateUser';
// import ThankYouPage from './ThankYouPage';
import ContactAndSamples from './ContactAndSamples';
import styles from '../../Styles/AuthComponent.module.scss';
// import styleContent from '../../Styles/Content.module.scss';
import { getDropDownValues } from '../../redux/coreSlice';

import flag from '../../img/flag.png';

// const { Content } = Layout;
// const { Title, Text } = Typography;

const Auth = ({ dropDowns }) => {
  // const code = getUrlParams('code');
  // eslint-disable-next-line no-unused-vars
  // const [validSession, setValidSession] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  const isAuth = location.pathname === '/auth' || location.pathname === '/auth/register';
  const isSessionSpecificRegpage = false; // location.pathname === '/auth/register';

  useEffect(() => {
    window.addEventListener('load', () => {
      document.documentElement.className = 'auth';
    });

    return () => {
      document.documentElement.className = '';
    };
  });

  useEffect(() => {
    dropDowns();
  }, [dropDowns]);

  // useEffect(() => {
  //   const sizing = () => {
  //     const isi = document.querySelector('.isi');
  //     console.log(isi);
  //     const preview = document.querySelector('.bottom-top');
  //     // isi.style.bottom = -1 * (isi.offsetHeight + preview.offsetHeight) + 'px';
  //   };

  //   window.addEventListener('load', sizing);
  //   window.addEventListener('resize', sizing);
  //   return () => {
  //     // remove resize listener
  //     window.removeEventListener('load', sizing);
  //     window.removeEventListener('resize', sizing);
  //   };
  // });

  const mainColWidth = isAuth ? 24 : 12;
  // if (isSessionSpecificRegpage) {
  //   mainColWidth = 18;
  // }

  return (
    <Layout className="root-subwrapper">
      <XKCHeader isAuth />
      {/* <Content className={styleContent.Content}> */}
      <Row className={`${styles['login-container']}`}>
        {isSessionSpecificRegpage && (
          <Col
            className={`bg-purple-600 ${styles['ant-col']} ${styles['login-bg']} ${
              styles[isAuth ? 'shrink' : '']
            } ${styles['mobile-flag']}`}
            style={{ position: 'relative', zIndex: 0, justifyContent: 'flex-start' }}
          >
            {/* <div className={styles.box} style={{ position: 'relative' }}> */}
            <img
              src={flag}
              style={{
                width: 250,
                display: 'block',
                top: -8,
                position: 'relative',
                zIndex: 2
              }}
              alt=""
            />
            {/* <Title
              level={1}
              className={styles.h1}
              style={{ lineHeight: 1, fontFamily: Arial, 'Sans Seriff' }}
            >
              XCOPRI NOW
            </Title>
            <Text className={`${styles.paragraph}`}>
              A gateway to engaging content from leading experts in mental health
            </Text>
          </div> */}
          </Col>
        )}

        <Col
          xl={mainColWidth}
          lg={mainColWidth}
          md={24}
          sm={24}
          flex="1"
          align="middle"
          className={`gradient--green ${styles['ant-col']}  ${styles['hide-scroll']}`}
          style={{ overflowY: 'scroll', backgroundColor: 'white', justifyContent: 'flex-start' }}
        >
          <div className={`${styles.formWrapper}`}>
            {/* {code && <SessionComponent code={code} />} */}
            <Switch>
              <Route path="/auth" exact component={NewAuthLanding} />
              <Route path="/auth/old-register" exact component={AuthComponent} />
              <Route path="/auth/register" component={NpiAuthComponent} />
              <Route
                path="/auth/login"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route path="/auth/logout" component={Logout} />
              <Route
                path="/auth/register"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route
                path="/auth/resetpassword/:userToken"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route
                path="/auth/resetpassword"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route path="/auth/authenticate/:userToken" component={AuthenticateUser} />
              <Route
                path="/auth/thankyou"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route path="/auth/:requestType/:externalId" component={ContactAndSamples} />
            </Switch>
          </div>
        </Col>
      </Row>
      {/* </Content> */}

      <Isi />
      <XKCFooter />
    </Layout>
  );
};

export default connect(null, { dropDowns: getDropDownValues })(Auth);
