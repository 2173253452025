import React from 'react';
import _ from 'lodash';
import { Modal, Row, Col, Typography } from 'antd';

import BorderButton from './BorderButton';

import config from '../../config';

const { Title } = Typography;

// const buttonStyle = {
//   backgroundColor: '#EFEFEF',
//   color: '#000000',
//   borderRadius: 3,
//   border: '1px solid #000000',
//   height: 30,
//   width: 75,
//   padding: '1px 6px',
//   cursor: 'pointer'
// };

const NpiLookup = (props) => {
  const { formValues, modalOpen, handleModalClose, setNpiInfo } = props;

  const [hcpList, setHcpList] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // const validateNpi = (value) => {
  //   return /^\d+$/.test(value) && value.length === 10;
  // };

  React.useEffect(() => {
    getHcps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const getHcps = () => {
    console.log('formValues is', formValues, 'modalOpen is', modalOpen);
    if (modalOpen) {
      const baseUrl = `${config.baseApiUrl}/api/npilookup?`;
      const params = _.filter(Object.keys(_.pickBy(formValues, _.identity)), (key) => {
        return _.includes(['first_name', 'last_name', 'npi', 'state'], key);
      })
        .map((key) => `${key === 'npi' ? 'number' : key}=${formValues[key].trim()}`)
        .join('&');

      const req = fetch(`${baseUrl}${params}`);

      req.then((response) => {
        response.json().then((json) => {
          // if (_.isEmpty(json.results)) {
          //   setNpiInfo(null);
          // } else {
          setHcpList(json.results);
          setLoading(false);
          // }
        });
      });
      req.catch((err) => {
        console.log('err on getting api is', err);
        setLoading(false);
        setNpiInfo(null);
      });
    }
  };

  const handleSelect = (hcp) => {
    const { basic, addresses, taxonomies } = hcp;

    console.log('hcp is', hcp);
    // const veevaInfo = {
    //   first_name: hcp.basic.first_name,
    //   last_name: hcp.basic.last_name,
    //   npi: hcp.number
    // };
    const npiInfo = {
      first_name: basic.first_name,
      middle_name: basic.middle_name,
      last_name: basic.last_name,
      npi: hcp.number
    };
    if (taxonomies.length) {
      npiInfo.licensed_state = hcp.taxonomies[0].state;
      npiInfo.license_number = hcp.taxonomies[0].license;
    }
    if (addresses.length) {
      npiInfo.address = addresses[0].address_1;
      npiInfo.office_phone = addresses[0].telephone_number;
      npiInfo.city = addresses[0].city;
      npiInfo.state = addresses[0].state;
      npiInfo.zip_code = addresses[0].postal_code;
    }
    setNpiInfo(npiInfo);
    setHcpList(null);
  };

  const renderHcps = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    if (hcpList && !hcpList.length) {
      return (
        <div className="flex flex-column horizontal-center vertical-center">
          <Title level={4} style={{ fontSize: 26 }}>
            Sorry, no results found.
          </Title>
          <br />
          <BorderButton
            wrapperStyle={{ marginTop: 10, textAlign: 'center' }}
            onClick={() => {
              setNpiInfo(null);
              setHcpList(null);
            }}
          >
            Close
          </BorderButton>
        </div>
      );
    }
    if (_.isArray(hcpList)) {
      return (
        <div>
          <Title level={4} style={{ fontSize: 26, marginBottom: 20 }}>
            Please select record with your information:
          </Title>
          <Row style={{ marginBottom: 10 }} justify="center">
            {_.map(hcpList, (hcp, idx) => {
              const { basic, addresses, taxonomies } = hcp;
              return (
                <Col
                  xl={10}
                  lg={10}
                  md={10}
                  sm={24}
                  xs={24}
                  key={idx}
                  style={{ margin: 20 }}
                  align="center"
                >
                  <b>
                    {hcp.number}
                    <br />
                    {`${basic.first_name} ${basic.middle_name ? basic.middle_name : ''} ${
                      basic.last_name
                    }`}
                  </b>
                  <br />
                  {addresses[0].address_1}
                  <br />
                  {`${addresses[0].city} ${addresses[0].state}`}
                  <br />
                  {`${taxonomies[0].state} ${taxonomies[0].license}`}
                  <br />
                  {taxonomies[0].desc}

                  <BorderButton
                    wrapperStyle={{ marginTop: 10, textAlign: 'center' }}
                    onClick={() => handleSelect(hcp)}
                  >
                    Select
                  </BorderButton>
                </Col>
              );
            })}
          </Row>
          ;
        </div>
      );
    }
  };

  return hcpList ? (
    <Modal
      key={'npiLookupModal'}
      visible={modalOpen}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      destroyOnClose
      footer={null}
      centered
      className="npi-lookup-modal"
      style={{
        padding: 'auto',
        top: 10,
        textAlign: 'center',
        width: 'auto',
        minWidth: _.isArray(hcpList) ? '50vw' : null,
        borderRadius: 10
      }}
    >
      {renderHcps()}
    </Modal>
  ) : (
    ''
  );
};

export default NpiLookup;
