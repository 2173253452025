/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable global-require */
import React from 'react';
import _ from 'lodash';
// import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Divider } from 'antd';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';
// import ReactGA from 'react-ga';

import { SessionCard, Header, InfoModal, XKCPolicy } from '../common';
// import BorderButton from '../common/BorderButton';
import styles from '../../Styles/UpcomingEvents.module.scss';
import { getSessions } from '../../redux/dataSlice';
import IndicationLine from '../common/IndicationLine';

import thumbnail_desktop from '../../img/XMC_Teaser_Video_Desktop.png';
import thumbnail_mobile from '../../img/XMC_Teaser_Video_Mobile.png';

// import GreenBox from '../common/GreenBox';
// import RegisterBanner from '../common/RegisterBanner';
// import config from '../../config';
// import degreeTitles from './degreeTitles.json';

// const img = require('../../img/jumbotron-primary.svg');

const { Title, Text } = Typography;

const UpcomingEvents = (props) => {
  const { sessions, mySessions, loading } = props;
  // const ref = useRef(null);
  // const [isMounted, setIsMounted] = React.useState(false);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);
  const [homeSessionsLoaded, setHomeSessionsLoaded] = React.useState(false);

  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [infoModalType, setInfoModalType] = React.useState(null);

  const filterMasterclass_n_Crossover_Sessions = (allSessions) => {
    const upcomingMasterclass_n_Crossover_Programs = _.filter(allSessions, (session) => {
      return _.includes([16, 17, 22, 23], session.clientprogram);
    });
    return upcomingMasterclass_n_Crossover_Programs;
  };

  React.useEffect(() => {
    return () => {
      // get new sessions when this component unmounts
      // console.log('getting more sessions');
      getSessions();
      setUpcomingSessions(filterMasterclass_n_Crossover_Sessions(sessions));
      setHomeSessionsLoaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // console.log('in useEffect', sessions, 'xxx', mySessions);

    if (
      // mySessions.length < 3 &&
      sessions.length &&
      !homeSessionsLoaded &&
      !upcomingSessions.length
    ) {
      // const now = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
      // const upcoming = _.filter(sessions, (session) => {
      //   return (
      //     !_.includes(mySessions, session.id) &&
      //     session.startdatetime >= now &&
      //     session.clientprogram === 7
      //   );
      // });
      setUpcomingSessions(filterMasterclass_n_Crossover_Sessions(sessions));
      // upcoming.map((session) => console.log(session.meetingtype));
      setHomeSessionsLoaded(true);
    }
  }, [sessions, mySessions, upcomingSessions, homeSessionsLoaded]);

  const toggleInfoModal = (statusCode) => {
    setInfoModalOpen(!infoModalOpen);
    let modalType = statusCode === 401 ? 'maxAttendedMeetings' : 'notEligible';
    if (statusCode === 406) {
      modalType = 'maxCapacity';
    }
    setInfoModalType(infoModalOpen ? null : modalType);
    if (infoModalOpen) {
      ReactGA.event({
        category: `InfoModal: ${infoModalType}`,
        action: 'Close'
      });
    }
  };

  const renderVideo = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 25
        }}
      >
        <video
          controls
          width="100%"
          height="auto"
          poster={isMobile ? thumbnail_mobile : thumbnail_desktop}
          style={{
            maxWidth: '800px'
          }}
        >
          <source
            src="https://xcopri-knowledge-center-beta.s3.amazonaws.com/assets/XMC+Teaser+Video_v4.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };

  const renderUpcomingSessions = () => {
    if (!upcomingSessions.length) {
      return (
        <div
          style={{
            textAlign: 'center',
            padding: '0px 20px',
            marginTop: 10,
            marginBottom: isMobile && -50,
            fontSize: '1.1rem'
          }}
        >
          <h3 style={{ fontWeight: 'bold', color: '#ce1f4a' }}>
            There are currently no live XCOPRI masterclass events with live Q&A scheduled.
          </h3>
        </div>
      );
    }
    return (
      <div>
        {renderVideo()}
        {upcomingSessions.length ? (
          upcomingSessions.map((session) => {
            return (
              <div key={session.id} style={{ width: '100%', maxWidth: 900, margin: '0 auto', padding: 20 }}>
                <SessionCard key={session.id} session={session} toggleInfoModal={toggleInfoModal} />
                <Divider size={50} />
              </div>
            );
          })
        ) : (
          <div
            style={{
              height: 150,
              textAlign: 'center'
              // fontSize: '1.2rem'
            }}
          >
            {!loading && (
              <Text style={{ color: '#ce1f4a', fontWeight: 'bold' }}>
                There are currently no live XCOPRI masterclass events with live Q&A scheduled.
              </Text>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <InfoModal handleClose={toggleInfoModal} modalType={infoModalType} open={infoModalOpen} />
      <div
        className={`flex flex-column ${styles['upcoming-sessions']}`}
        style={{ minHeight: 1, alignItems: 'center', width: '100%', flex: 'unset' }}
      >
        <Header
          styles={styles}
          masterClassLogo
          title={() => (
            <div style={{ marginLeft: 20 }}>
              <Title
                style={{
                  marginTop: 0
                  // marginBottom: 15,
                  // fontFamily: 'Arial',
                  // fontWeight: 600
                }}
                className="text__white"
                level={3}
              >
                Register for XCOPRI masterclass!
              </Title>
              {upcomingSessions.length > 0 && (
                <Text style={{ marginTop: 0, marginBottom: 0 }} className="text__white">
                  We recognize that you may have questions about XCOPRI. This series was designed to enhance your
                  knowledge of XCOPRI, so you are confident in prescribing for your patients. <br />
                  <br />
                  XCOPRI masterclass provides an opportunity to learn from and engage with nationally recognized experts
                  in epilepsy. Each class highlights the clinical profile of XCOPRI, as well as the expert&apos;s
                  real-world experiences with XCOPRI. <br />
                  <br />
                  At the end of each virtual class, participate in an “ask the master” session, in which you will
                  interact directly with the featured master to gain additional insights to inform clinical
                  decision-making for your patients.
                </Text>
              )}
              <IndicationLine />
            </div>
          )}
        />
      </div>
      <div className="flex flex-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center',
            display: 'block',
            width: '100%'
          }}
        >
          {renderUpcomingSessions()}
        </div>
      </div>
      <XKCPolicy isMasterClass />
    </>
  );
};

const mapStateToProps = ({ data, auth }) => {
  const { sessions, mySessions, loaded, loading } = data;
  return { loaded, loading, sessions, mySessions, user: auth.user };
};
export default connect(mapStateToProps, { getSessions })(withRouter(UpcomingEvents));
