import React from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import BorderButton from './BorderButton';
import { updatePassword, loginUserSuccess } from '../../redux/authSlice';
import config from '../../config';

const ReSetPasswordForm = (props) => {
  const [pwdSubmitDisabled, setPwdSubmitDisabled] = React.useState(true);

  const { userToken } = props;
  // console.log(userToken, props);

  const onPasswordFormFinish = (values) => {
    // console.log('Success:', values);
    if (userToken) {
      fetch(`${config.baseApiUrl}/auth/resetpassword`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userToken: userToken,
          newPassword: values.password1
        })
      }).then((response) => {
        if (response.status !== 200) {
          console.warn('something went wrong....');
        }
        response.json().then((userData) => {
          loginUserSuccess({
            accessToken: userData.access,
            refreshToken: userData.refresh,
            user: userData.user,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userData.access}`
            }
          });
        });
        // for some reason the redux action takes too long so we need a timeout before redirecting
        setTimeout(() => {
          props.history.push('/');
        }, 200);
      });
    } else {
      props.updatePassword(values.password1);
    }
  };

  const onPasswordFormFinishFailed = (errorInfo) => {
    console.log('onEmailFormFinishFailed Failed:', errorInfo);
  };

  return (
    <Form
      layout="vertical"
      name="password_form"
      onFinish={onPasswordFormFinish}
      onFinishFailed={onPasswordFormFinishFailed}
    >
      <p>{props.formTitle}</p>
      <Form.Item
        label="New Password"
        name="password1"
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please input your new password!'
          },
          {
            validator: (rule, value) => {
              if (value.length < 8 || !/[A-Z]/.test(value) || !/[0-9]/.test(value)) {
                return Promise.reject(
                  new Error(
                    'Passwords must have at least 8 characters, contain at least one uppercase letter, and at least one number'
                  )
                );
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input.Password autoComplete="off" />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="password2"
        dependencies={['password1']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your new Password!'
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              // console.log('getFieldValue', getFieldValue('password1'), 'value', value);
              if (!value || getFieldValue('password1') === value) {
                setPwdSubmitDisabled(false);
                return Promise.resolve();
              }
              setPwdSubmitDisabled(true);
              return Promise.reject(new Error('The two passwords you entered do not match!'));
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <BorderButton
          disabled={pwdSubmitDisabled}
          // style={{ marginBottom: 8, width: '100%' }}
          // block
          // type="primary"
          htmlType="submit"
          text={props.buttonLabel}
        />
      </Form.Item>
    </Form>
  );
};

export default connect(null, { updatePassword, loginUserSuccess })(ReSetPasswordForm);
