/* eslint-disable global-require */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Layout } from 'antd';
import { XKCFooter, XKCHeader, Isi } from './common';
import DashBoard from './DashBoard';
import styles from '../Styles/Content.module.scss';

const { Content } = Layout;

const Main = (props) => {
  const elemRef = React.useRef(null);

  const isLoggedIn = props.auth.user && props.auth.accessToken;

  const scrollToTop = () => {
    elemRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (elemRef.current) {
      // scrollToTop();
    }
  }, [elemRef]);

  return (
    <Layout className="root-subwrapper">
      <XKCHeader scrollToTop={scrollToTop} isAnon={!isLoggedIn} />
      <Content className={styles.Content}>
        {/* NEED THIS FOR MAIN WRAPPING IF THERES A BG - DONT DELETE */}{' '}
        <div className={styles['site-layout-background']} ref={elemRef}>
          {/* NEED THIS FOR MAIN WRAPPING & SCROLLING - DONT DELETE */}
          <div className={styles['site-layout-content']} style={{ minHeight: 380 }}>
            <DashBoard isAnonEpiReq={props.isAnonEpiReq} />
            <Isi />
          </div>
        </div>
      </Content>
      <XKCFooter />
    </Layout>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default withRouter(connect(mapStateToProps)(Main));
