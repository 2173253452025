import React from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import BorderButton from '../BorderButton';

import { getUrlParams } from '../../../config';

const { Title, Text } = Typography;

const RegisterWidget = () => {
  const history = useHistory();

  const code = getUrlParams('code');

  return (
    <div className="widget-wrapper register-widget">
      <Title id={'register-form-title'} style={{ marginBottom: 0, textAlign: 'left' }} level={4}>
        Are you visiting XCOPRI NOW for the first time?
      </Title>
      <Text
        // id={'register-form-summary'}
        style={{
          marginBottom: 20,
          marginTop: 5,
          display: 'block',
          textAlign: 'left',
          fontFamily: 'din-2014',
          color: '#54565b',
          fontSize: 18
        }}
      >
        In order to register for the specific program that interests you, please register below.
        <span
          style={{
            color: '#5B2C86',
            fontWeight: 800,
            fontSize: 16
          }}
        >
          {' '}
          You&apos;ll need to register once for full access to XCOPRI NOW.
        </span>
      </Text>
      <BorderButton onClick={() => history.push(`/auth/register${code ? `?code=${code}` : ''}`)}>
        Register
      </BorderButton>
    </div>
  );
};

export default RegisterWidget;
