import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Form, Input, Modal, Spin } from 'antd';
import {
  // MinusCircleOutlined,
  // PlusOutlined,
  LoadingOutlined,
  CheckCircleTwoTone
} from '@ant-design/icons';
import BorderButton from './BorderButton';

import config from '../../config';

const ShareModal = (props) => {
  const { open, stateAction, session, auth } = props;

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [savedShareEmails, setSavedShareEmails] = React.useState([]);
  const [isSharedEmailsSent, setIsSharedEmailsSent] = React.useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = React.useState(true);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      session: session,
      hcps: savedShareEmails.concat([values])
    };
    config
      .fetch(`${config.baseApiUrl}/api/share`, {
        method: 'POST',
        headers: auth.headers,
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          ReactGA.event({
            category: 'Share',
            action: 'Sent Successful'
          });
        }
        setLoading(false);
        setIsSharedEmailsSent(true);
        form.resetFields();
        setSavedShareEmails([]);
      });
  };

  const handleModalOk = (e) => {
    // console.log(e);

    stateAction(false);
    setLoading(false);
    setSuccess(false);
    setIsSharedEmailsSent(false);
    setSavedShareEmails([]);
    ReactGA.event({
      category: 'ShareModal',
      action: 'close'
    });
  };

  const indicator =
    loading && !success ? (
      <LoadingOutlined style={{ fontSize: 64, marginLeft: -40, color: '#624B78' }} spinning />
    ) : (
      <CheckCircleTwoTone twoToneColor="#00a7b5" style={{ fontSize: 82, marginLeft: -40 }} />
    );

  const checkSendButtonDisabled = (emails, formValues) => {
    let isDisabled = true;
    if (emails.length || (!_.isEmpty(formValues) && _.every(_.values(formValues)))) {
      isDisabled = false;
    }

    setIsSendButtonDisabled(isDisabled);
  };

  const removeSavedShareEmail = (item) => {
    const upsatedSavedShareEmails = savedShareEmails.filter(
      (savedShareEmail) => savedShareEmail.email !== item
    );
    setSavedShareEmails(upsatedSavedShareEmails);
    checkSendButtonDisabled(upsatedSavedShareEmails, form.getFieldValue());
  };

  const renderSavedShareEmails = () => {
    if (savedShareEmails.length > 0) {
      return (
        <div
          style={{ display: 'flex', flexWrap: 'wrap', margin: '-9px 0 5px 0' }}
          className="share_modal"
        >
          {savedShareEmails.map((savedShareEmail, idx) => {
            return (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  backgroundColor: '#E3E5E4',
                  borderRadius: 5,
                  padding: '5px 5px 5px 10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 5
                }}
              >
                <span style={{ flex: 1 }}>{savedShareEmail.email}</span>
                <button
                  type="button"
                  style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}
                  onClick={() => removeSavedShareEmail(savedShareEmail.email)}
                >
                  x
                </button>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const onAdd = () => {
    const values = form.getFieldValue();
    if (_.isEmpty(values) || !_.every(_.values(values))) {
      return;
    }
    setSavedShareEmails([
      ...savedShareEmails,
      { first_name: values.first_name, last_name: values.last_name, email: values.email }
    ]);
    form.resetFields();
  };

  const renderModalContent = () => {
    if (isSharedEmailsSent) {
      return (
        <>
          <p style={{ color: '#5B2C86', fontSize: 24, fontWeight: 900 }}>
            Thank you!
            <br />
            An email has been sent to your intended recipient(s).
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              margin: '0px auto'
            }}
          >
            <BorderButton
              type="primary"
              htmlType="submit"
              text={'CLOSE'}
              onClick={handleModalOk}
              gtagtext={{ text: 'Confirm' }}
              gtagid={{ id: 'Confirm_Event_Submission' }}
            />
          </div>
        </>
      );
    }

    return (
      <div className="share-email">
        <h2 style={{ color: '#5B2C86' }}>Share via Email</h2>
        <p style={{ color: '#828282' }}>
          Please enter up to 5 email addresses for colleagues who may want to join you for this
          session.
        </p>
        {renderSavedShareEmails()}
        <Form
          name="dynamic_form_nest_item"
          form={form}
          onFinish={onFinish}
          onValuesChange={(changedValues, allValues) => {
            checkSendButtonDisabled(savedShareEmails, allValues);
          }}
          autoComplete="off"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: 450,
              padding: 0
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
              <div style={{ color: '#797979' }}>First Name</div>
              <Form.Item name={'first_name'} required>
                <Input />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
              <div style={{ color: '#797979' }}>Last Name</div>
              <Form.Item name={'last_name'} required>
                <Input />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
              <div style={{ color: '#797979' }}>Email</div>
              <Form.Item
                name={'email'}
                required
                rules={[{ type: 'email', message: 'Not a valid email.' }]}
                validateTrigger={'onBlur'}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '22px 8px'
            }}
          >
            <div
              onClick={savedShareEmails.length < 5 ? onAdd : null}
              className="share-submit-btn"
              style={{
                padding: '5px 20px',
                cursor: savedShareEmails.length < 5 ? 'pointer' : 'not-allowed',
                opacity: savedShareEmails.length < 5 ? 1 : 0.5,
                color: '#615571',
                border: '1px solid #615571',
                backgroundColor: 'transparent'
              }}
            >
              Add
            </div>
          </div>
          <BorderButton
            type="primary"
            htmlType="submit"
            text={'Send'}
            disabled={isSendButtonDisabled}
            gtagtext={{ text: 'Send' }}
            gtagid={{ id: 'Send_Share_Event_Submission' }}
          />
        </Form>
      </div>
    );
  };

  return (
    <Modal
      className="share-modal"
      key={'modal'}
      visible={open}
      onOk={handleModalOk}
      onCancel={handleModalOk}
      footer={null}
    >
      <Spin spinning={loading} indicator={indicator}>
        {renderModalContent()}
      </Spin>
    </Modal>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps, null)(ShareModal);
