/* eslint-disable react/no-danger */
import React from 'react';
import ReactGA from 'react-ga';
import { Modal } from 'antd';
import VideoPlayer from './VideoPlayer';

const VideoModal = (props) => {
  const { open, stateAction, videoUrl, embedCode } = props;

  const handleModalClose = (e) => {
    stateAction(false);
    ReactGA.event({
      category: 'VideoModal',
      action: `Close: ${videoUrl}`
    });
  };

  // console.log('VideoModal open', open, 'videoUrl', videoUrl, 'embedCode', embedCode);

  return (
    <Modal
      key={'modal'}
      visible={open}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      destroyOnClose
      footer={null}
      centered
      width={'80%'}
      // style={{ resize: 'none', padding: 'auto', top: 10 }}
    >
      {embedCode ? (
        <div
          style={{ width: '80%', margin: '0 auto' }}
          dangerouslySetInnerHTML={{ __html: embedCode }}
        />
      ) : (
        <VideoPlayer videoUrl={videoUrl} />
      )}
    </Modal>
  );
};

export default VideoModal;
