import React from 'react';
// import { Divider } from 'antd';
import { isMobile } from 'react-device-detect';
import flag from '../../img/flag.png';
import mobileFlag from '../../img/img/flag-mobile.png';

import masterClassLogoImg from '../../img/XMC Header Logo Lockup.png';
import masterClassLogoMobile from '../../img/XMC Header Logo Lockup_Mobile.png';

const Header = ({ title, styles, masterClassLogo }) => {
  return (
    <section
      // bg-layer
      className="red-gradient wrapper padding block "
      style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
    >
      <div
        className="desktop-block"
        style={{
          textAlign: 'left',
          padding: masterClassLogo ? '0 50px 40px 0' : '0 50px 0 0',
          justifyContent: 'flex-start',
          width: 'auto'
        }}
      >
        {isMobile ? (
          <img
            src={masterClassLogo ? masterClassLogoMobile : mobileFlag}
            alt=""
            className={masterClassLogo ? 'masterclass-mobile-logo' : ''}
            style={{ width: 250, position: 'relative', top: -9, margin: '0 auto' }}
          />
        ) : (
          <img
            src={masterClassLogo ? masterClassLogoImg : flag}
            alt=""
            style={{
              maxWidth: masterClassLogo && 165,
              width: 250,
              position: 'relative',
              top: -8,
              margin: '0 auto'
            }}
          />
        )}
      </div>
      <div
        style={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 20,
          // marginBottom: 'auto',
          position: 'relative',
          top: 0
        }}
      >
        <div style={{ width: '100%', maxWidth: 1200, margin: '20px auto', textAlign: 'left' }}>
          {title()}
        </div>
      </div>
      <div className="desktop" style={{ flex: 1 }} />
    </section>
  );
};
export default Header;
