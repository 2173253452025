/* eslint-disable global-require */
import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { Layout } from 'antd';

import { getDropDownValues } from '../../redux/coreSlice';
import { XKCFooter, XKCHeader, Isi } from '../common';
import UpcomingEvents from './UpcomingEvents';
// import MasterClassListing from './MasterClassListing';
import styles from '../../Styles/Content.module.scss';

const { Content } = Layout;

const Main = (props) => {
  const { dropDowns } = props;

  React.useEffect(() => {
    dropDowns();
  }, [dropDowns]);

  return (
    <Layout>
      <XKCHeader isAnon />
      <Content className={styles.Content}>
        {/* NEED THIS FOR MAIN WRAPPING IF THERES A BG - DONT DELETE */}
        <div className={styles['site-layout-background']}>
          {/* NEED THIS FOR MAIN WRAPPING & SCROLLING - DONT DELETE */}
          <div className={styles['site-layout-content']} style={{ minHeight: 380 }}>
            <UpcomingEvents />
            <Isi />
          </div>
        </div>
      </Content>
      <XKCFooter />
    </Layout>
  );
};

export default withRouter(connect(null, { dropDowns: getDropDownValues })(Main));
