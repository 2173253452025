import React from 'react';
import styles from '../../Styles/NewsAndViews.module.scss';

const IndicationLine = () => {
  return (
    <div className={styles.IndicationLine}>
      <p>
        XCOPRI&reg; (cenobamate tablets) CV is indicated for the treatment of partial-onset seizures
        in adult patients.
      </p>
    </div>
  );
};

export default IndicationLine;
