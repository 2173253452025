import React from 'react';
import { Typography } from 'antd';
import BorderButton from './BorderButton';

const { Title } = Typography;

const XKCExpertBar = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className="xpert-bar"
        style={{
          background: '#dbe4ea',
          padding: 30,
          margin: '50px',
          marginTop: 100,
          textAlign: 'left',
          borderRadius: 3,
          display: 'block'
        }}
      >
        <Title level={4} style={{ fontWeight: 'bold' }}>
          Explore XCOPRI MASTERS&apos; CLASS
        </Title>
        These events have open enrollment and provide you with the opportunity to get your specific
        questions answered about the clinical development program for XCOPRI, efficacy and safety of
        XCOPRI, considerations when initiating XCOPRI, and managing the adjunctive use of XCOPRI.
        <br />
        <br />
        <a
          onClick={() => {
            window.location.href = '/xmc';
          }}
          href
        >
          <BorderButton gtagtext={{ text: 'XPLORE NOW' }} gtagid={{ id: 'XPLORE_Now_CTA_Link' }}>
            XPLORE NOW!
          </BorderButton>
        </a>
      </div>
    </div>
  );
};

export default XKCExpertBar;
