import React from 'react';
import { Typography } from 'antd';
import { Header, IndicationLine } from '../common';

const { Title, Text } = Typography;

const AuthHeaderBanner = () => {
  return (
    <Header
      title={() => (
        <div className="header-text">
          <Title
            className="text__white"
            style={{ marginBottom: 0, fontFamily: 'din-2014' }}
            level={2}
          >
            Welcome to XCOPRI NOW
          </Title>
          <Text
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: 24,
              lineHeight: 1.1,
              fontFamily: 'din-2014'
            }}
            className="text__white dinFont"
          >
            Register for upcoming expert-led XCOPRI events
          </Text>
          <IndicationLine />
          {/* <Divider style={{ height: 50, border: 0 }} type="vertical" /> */}
        </div>
      )}
    />
  );
};

export default AuthHeaderBanner;
