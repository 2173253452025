import React from 'react';
import { Typography } from 'antd';
import BorderButton from './BorderButton';

import styles from '../../Styles/XKCMasterclassBar.module.scss';

const bannerLogo = require('../../img/XMC_banner_logo.png');

const { Text, Title } = Typography;

const XKCMasterclassBar = ({ extraStyles }) => {
  return (
    <div className={`${styles['masterclass-bar']}`} style={extraStyles}>
      <img src={bannerLogo} alt="masterclass logo" className={`${styles['masterclass-logo']}`} />
      <div className={`${styles['masterclass-text']}`}>
        <Title level={4} style={{ fontWeight: 'bold' }}>
          Elevate Your Knowledge Through XCOPRI masterclass
        </Title>
        <Text style={{ fontSize: 15 }}>
          We recognize that you may have questions about XCOPRI. This series was designed to enhance
          your knowledge of XCOPRI, so you are confident in prescribing for your patients.
        </Text>
        <br />
        <div style={{ height: 6, width: '100%' }} />
        <Text style={{ fontSize: 15 }}>
          XCOPRI masterclass provides an opportunity to learn from and engage with nationally
          recognized experts in epilepsy. Each class highlights the clinical profile of XCOPRI, as
          well as the expert&apos;s real-world experiences with XCOPRI.
        </Text>{' '}
        <br />
        <div style={{ height: 6, width: '100%' }} />
        <Text style={{ fontSize: 15 }}>
          At the end of each virtual class, participate in an “ask the master” session, in which you
          will interact directly with the featured master to gain additional insights to inform
          clinical decision-making for your patients.
        </Text>
        <br />
        <div style={{ height: 15, width: '100%' }} />
        <BorderButton
          gtagtext={{ text: 'XCOPRI MASTERCLASS' }}
          gtagid={{ id: 'XCOPRI_MATERCLASS_CTA_Link' }}
          onClick={() => {
            window.location.href = '/xmc';
          }}
        >
          Enroll Today!
        </BorderButton>
      </div>
    </div>
  );
};

export default XKCMasterclassBar;
