import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Space, Row, Col, Avatar, Typography, Divider } from 'antd';
import { HomeFilled, UserOutlined } from '@ant-design/icons';
import styles from '../../Styles/SessionCard.module.scss';
import time from '../../img/time.png';
import calendar from '../../img/calendar.png';
import person from '../../img/person.png';
const { Title, Text } = Typography;

const Styles = {
  dividerStyle: {
    margin: '10px 0',
    marginTop: 10,
    marginBottom: 10
  },
  actionButtonStyle: {
    borderRadius: 4,
    color: '#3C6279',
    lineHeight: 1,
    padding: '0px 40px',
    fontSize: 17,
    fontFamily: 'Arial',
    marginTop: 5,
    borderWidth: 2
  }
};

const SessionCardIcons = (props) => {
  const { session, preTitle, isAuth } = props;

  const colSize = isAuth ? 24 : 12;

  const getMeetingTypeOrLocation = () => {
    const meetingtype = session.is_virtual_hybrid ? 'Virtual / In-Person' : session.meetingtype;

    if (
      _.includes(['1:1', 'In-Office', 'Out-Of-Office'], session.meetingtype) ||
      session.is_virtual_hybrid
    ) {
      const { venue } = session;
      if (venue.name === 'Virtual') {
        return (
          <>
            <HomeFilled
              style={{ width: 20, marginRight: 5, paddingTop: 3 }}
              className="text--turqoise"
            />
            {meetingtype} ({venue.name})
          </>
        );
      }
      return (
        <>
          <div style={{ display: 'flex' }}>
            <>
              <HomeFilled
                style={{ width: 20, marginRight: 5, paddingTop: 3 }}
                className="text--turqoise"
              />
            </>
            <div>
              {meetingtype}
              <br />
              {venue.name}
              <br />
              {venue.address1}, {venue.address2}
              <br />
              {venue.city}, {venue.state}, {venue.postalcode}
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <img style={{ width: 20, marginRight: 5 }} src={person} alt="" />
        {meetingtype}
      </>
    );
  };

  const colStyle = {};

  return (
    <>
      {preTitle && (
        <Title
          style={{ marginBottom: 0, color: '#624b78' }}
          className={`${styles['title--purple']}`}
          level={4}
        >
          {preTitle}
        </Title>
      )}
      <Title
        style={{ marginTop: 0, color: '#624b78', fontSize: 28 }}
        className={`${styles['title--purple']}`}
        level={2}
      >
        {session.title}
      </Title>
      <Row style={{ justifyContent: 'space-between' }}>
        <Col xl={colSize} lg={colSize} md={colSize} sm={colSize}>
          <div className="direction-column icons" style={colStyle}>
            <Space direction="vertical">
              <Text style={{ color: '#54565b' }}>
                {/* <CalendarFilled className="text--turqoise" /> */}
                <img style={{ width: 20, marginRight: 5 }} src={calendar} alt="" />
                {moment(session.startdatetime).format('dddd, MMMM D, YYYY')}
              </Text>
              <Text style={{ color: '#54565b' }}>
                {/* <ClockCircleFilled className="text--turqoise" />  */}
                <img style={{ width: 20, marginRight: 5 }} src={time} alt="" />
                {moment(session.startdatetime).format('h:mm A')} (
                {session.timezone.replace(/\s/g, '')})
              </Text>
              <Text style={{ color: '#54565b' }}>{getMeetingTypeOrLocation()}</Text>
            </Space>
          </div>
        </Col>
        <Col
          className="sessionCardAvatarWrapper"
          xl={colSize}
          lg={colSize}
          md={colSize}
          sm={colSize}
        >
          <SessionCardAvatar speaker={session.speaker} cardStyle={colStyle} isAuth={isAuth} />
          {session.speaker2 && (
            <>
              {!isAuth && <br />}
              <SessionCardAvatar
                speaker={session.speaker2}
                isSecondarySpeaker
                cardStyle={colStyle}
                isAuth={isAuth}
              />
            </>
          )}
          {session.speaker3 && (
            <>
              <br />
              <SessionCardAvatar
                speaker={session.speaker3}
                isSecondarySpeaker
                cardStyle={colStyle}
                isAuth={isAuth}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const SessionCardAvatar = (props) => {
  const { speaker, isSecondarySpeaker, isAuth } = props;
  // console.log(speaker);

  // const renderSpeaker = (speakerObj) => {};

  return (
    <>
      {((isAuth && !isSecondarySpeaker) || !isAuth) && (
        <Divider
          className={`cardAvatarDetailsWrapperDivider ${
            props.isAuth ? 'cardAvatarDetailsWrapperDividerAuth' : ''
          }`}
          style={Styles.dividerStyle}
        />
      )}
      <div
        className={`flex cardAvatarDetailsWrapper ${
          props.isAuth ? 'cardAvatarDetailsWrapperAuth' : ''
        }`}
        style={props.cardStyle}
      >
        <Avatar
          className="cardAvatar"
          shape="square"
          size={70}
          src={speaker.avatar}
          style={{ flexShrink: 0, color: '#624b78' }}
          icon={!speaker.avatar && <UserOutlined />}
        />
        <div style={{ marginLeft: 10, wordBreak: 'keep-all', color: '#5B2C86' }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              wordBreak: 'keep-all'
            }}
          >
            {speaker.firstname} {speaker.lastname}, {speaker.title}
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              wordBreak: 'keep-all',
              lineHeight: 1.1
            }}
          >
            {speaker.orgname}
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              wordBreak: 'keep-all'
            }}
          >
            {speaker.city}, {speaker.state}
          </div>
        </div>
      </div>
      <Divider
        className={`cardAvatarDetailsWrapperDivider cardAvatarDetailsWrapperDividerBottom ${
          props.isAuth ? 'cardAvatarDetailsWrapperDividerAuth' : ''
        }`}
        style={Styles.dividerStyle}
      />
    </>
  );
};

export { SessionCardIcons, SessionCardAvatar };
