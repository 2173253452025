/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
// import { Divider } from 'antd';
import config from '../../config';
// import { SessionCard, VideoModal } from '../common';
import { SessionCard, InfoModal } from '../common';
import { setData } from '../../redux/dataSlice';
// import { gtagConversion } from '../../util/gtag';

const TopSessionComponent = (props) => {
  // eslint-disable-next-line no-shadow
  const { auth, data, setData, setSessionId } = props;
  const { mySessions, sessions } = data;
  // eslint-disable-next-line import/no-named-as-default-member
  const code = config.getUrlParams('code');
  const confirmed_non_affiliation = config.getUrlParams('confirmed_non_affiliation') === '1';
  const isVirtualHybrid = config.getUrlParams('isVirtualHybrid') || 0;
  // const attendeereferral = config.getUrlParams('attendeereferral') || '';

  // const [videoModalOpen, setVideoModalState] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState({ loading: false, loaded: false });
  const [session, setSession] = React.useState(null);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState(null);
  const [registerModalOpen, setRegisterModalOpen] = React.useState(false);
  const [registerModalType, setRegisterModalType] = React.useState(null);
  const [openPatientCaseForm, setOpenPatientCaseForm] = React.useState(false);

  const setFirstRegisteredSession = () => {
    const date = new Date();
    const today = date.toJSON().split('T')[0];

    const firstSession = _.find(
      _.map(mySessions, (sessionId) => {
        return _.find(sessions, { id: sessionId });
      }),
      (sessionObj) => {
        return sessionObj && sessionObj.startdatetime.split('T')[0] >= today;
      }
    );
    if (firstSession) {
      setSessionId(firstSession.id);
      setSession(firstSession);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalType(null);
    ReactGA.event({
      category: `InfoModal: ${modalType} `,
      action: 'Close'
    });
  };

  React.useEffect(() => {
    // console.log('in useffect', sessions, mySessions, loadingState, data.loaded);
    if (data.loaded && !loadingState.loading && !loadingState.loaded) {
      // console.log('code', code, 'session', session);
      if (code && !session) {
        setLoadingState({ ...loadingState, loading: true });

        const request = config.fetch(`${config.baseApiUrl}/api/toggleregistration`, {
          method: 'POST',
          headers: auth.headers,
          body: JSON.stringify({
            code,
            confirmed_non_affiliation,
            isVirtualHybrid
            // attendeereferral
          })
        });
        request.then((response) => {
          setLoadingState({ loading: false, loaded: true });

          if (response.status === 404) {
            setFirstRegisteredSession();
            return;
          }
          if (response.status === 406) {
            // alert('max sessions reached popup');
            setModalType('maxCapacity');
            setModalOpen(true);
            ReactGA.event({
              category: 'Session Registration RSVP',
              action: `User tried to register for meeting at max capacity (${code})`
            });
            setFirstRegisteredSession();
            return;
          }
          if (response.status === 401) {
            // alert('max sessions reached popup');
            setModalType('maxAttendedMeetings');
            setModalOpen(true);
            ReactGA.event({
              category: 'Session Registration RSVP',
              action: `User Attended more than 1 meeting per title (${code})`
            });
            setFirstRegisteredSession();
            return;
          }
          response.json().then((responseData) => {
            if (response.status === 206) {
              setRegisterModalOpen(true);
              setRegisterModalType('nonAffiliation');
              setSessionId(responseData.id);
              setSession(responseData);
              return;
            }
            if (response.status === 403) {
              // alert('office staff cant register for out of office');
              setRegisterModalOpen(true);
              setRegisterModalType('mealIneligibility');
              ReactGA.event({
                category: 'Session Registration RSVP',
                action: `User can not have a meal for this session (${code})`
              });
              return;
            }
            // eslint-disable-next-line no-shadow
            const { mySessions, sessions } = responseData;
            setData({ mySessions, sessions });
            const registeredSession = _.find(sessions, { invitationcode: code });
            setSessionId(registeredSession.id);
            setSession(registeredSession);
            setOpenPatientCaseForm(true);
          });
        });
        ReactGA.event({
          category: 'Session Registration RSVP',
          action: `RSVP session registration ${code}`
        });
      } else if (!code && !session && mySessions.length) {
        setLoadingState({ ...loadingState, loading: true });
        setFirstRegisteredSession();
        setLoadingState({ loading: false, loaded: true });
      }
    }
  }, [auth, code, session, mySessions, sessions, setData, loadingState]); // eslint-disable-line

  // console.log('session', session, 'modalOpen', modalOpen);

  if (modalOpen) {
    return <InfoModal handleClose={handleModalClose} modalType={modalType} open={modalOpen} />;
  }

  if (!session) {
    return '';
  }

  return (
    <>
      <SessionCard
        // style={{ width: '100%', maxWidth: 800 }}
        session={session}
        registerModalOpen={registerModalOpen}
        registerModalType={registerModalType}
        openPatientCaseForm={openPatientCaseForm}
        hidePreviewButton
      />
      {/* <Divider type="vertical" style={{ height: 20 }} />
      <img
        src={session.video.thumbnail}
        style={{ width: '100%', cursor: 'pointer', flexShrink: 0 }}
        onClick={() => setVideoModalState(true)}
        alt="video thumbnail"
      />

      <VideoModal
        open={videoModalOpen}
        stateAction={setVideoModalState}
        videoUrl={session.video.url}
      /> */}
    </>
  );
};

const mapStateToProps = ({ auth, data }) => {
  return { auth, data };
};

export default connect(mapStateToProps, { setData })(TopSessionComponent);
