import React from 'react';

import { Typography, Divider } from 'antd';

import styles from '../../../Styles/RegisterAndProfileForm.module.scss';

const { Text, Title } = Typography;

const FormWrapper = ({ progressClass, title, children }) => {
  return (
    <div className="register-form-wrapper">
      <Title id={'register-form-title'} style={{ marginBottom: 0 }} level={2}>
        Register for an account to get started.
      </Title>
      <Text
        id={'register-form-summary'}
        style={{
          marginBottom: 5,
          marginTop: 5,
          display: 'block',
          fontSize: 24,
          lineHeight: 1.2
        }}
      >
        Please fill out the form below.{' '}
        <span
          style={{
            fontWeight: 800,
            fontSize: 24
          }}
        >
          You&apos;ll only have to register once for full access.
        </span>
      </Text>

      <div className={`${styles.regProgress} ${styles[progressClass]}`} />

      <Title id={'register-form-title'} style={{ marginBottom: 0 }} level={4}>
        {title}
      </Title>
      <Text id={'register-form-splat'}>
        <span>*</span>Indicates a required field
      </Text>
      <Divider style={{ height: 20 }} />
      {children}
    </div>
  );
};

export default FormWrapper;
