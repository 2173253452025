import React from 'react';
import { Layout } from 'antd';
import styles from '../../Styles/XKCFooter.module.scss';
// import Isi from '../common/Isi';
import logo from '../../img/footer-logo.png';
import bar from '../../img/rainbow.png';
import privacy from '../../img/img/privacyoptions123x59.png';

const { Footer } = Layout;
// const { Text } = Typography;

const XKCFooter = (props) => {
  return (
    <Footer>
      <div className={`${styles['rainbow-bar']}`}>
        <img src={bar} alt="footer bar" style={{ width: '100%', height: '10px', margin: '0px' }} />
      </div>
      <div className={`${styles['itc-footer']} ${props.isAlwaysVisible ? styles.absoluteVisible : ''}`}>
        <div>
          <img alt="logo" src={logo} className={`${styles['itc-footer-logo']}`} style={{ padding: 20 }} />
        </div>
        <div className={`${styles['itc-footer-right']} endmatter`} style={{ flex: 1 }}>
          <ul style={{ whiteSpace: 'no-wrap' }} className={`${styles['itc-footer-right-links']}`}>
            <li style={{ marginRight: 4 }}>
              <a
                href="http://www.xcoprihcp.com/wp-content/uploads/2022/06/xcopri-hcp-pi-med-guide.pdf"
                target="_blank"
                rel="noopener noreferrer"
                id="Prescribing_Information_Link"
                text="Prescribing Information"
              >
                Prescribing Information
              </a>{' '}
              |
            </li>
            <li style={{ marginRight: 4 }}>
              <a
                href="https://www.xcoprihcp.com/wp-content/uploads/2022/06/xcopri_cenobamate_medical_guide.pdf"
                target="_blank"
                rel="noopener noreferrer"
                id="Medication_Guide_Link"
                text="Medication Guide"
              >
                Medication Guide
              </a>{' '}
              |
            </li>
            <li style={{ marginRight: 4 }}>
              <a
                href="https://xcoprihcp.com/"
                id="XCOPRIHCP.com_Link"
                text="XCOPRIHCP.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                XCOPRIHCP.com
              </a>{' '}
              |
            </li>
            <li style={{ marginRight: 4 }}>
              <a
                href="https://www.sklifescienceinc.com/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
                id="Terms_Of_Use_Link"
                text="Terms of Use"
              >
                Terms of Use
              </a>{' '}
            </li>
            <br />
            <li style={{ marginRight: 4 }}>
              <a
                href="https://www.sklifescienceinc.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                id="Privacy_Policy_Link"
                text="Privacy Policy"
              >
                Privacy Policy
              </a>{' '}
              |
            </li>
            <li style={{ marginRight: 4 }}>
              <a
                href="https://www.sklifescienceinc.com/privacy-policy/#section-3"
                target="_blank"
                rel="noopener noreferrer"
                id="Privacy_Policy_Link"
                text="Your Privacy Choices"
              >
                Your Privacy Choices
              </a>
            </li>
            <img src={privacy} alt="" style={{ width: '3%', marginTop: -3 }} /> |&nbsp;
            <li style={{ marginRight: 4 }}>
              <a
                href="https://www.sklifescienceinc.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                id="Contact_Us_Link"
                text="Contact Us"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <div className={`${styles['itc-footer-right-trademark']}`}>
            &copy; 2023 SK Life Science, Inc., a subsidiary of SK Biopharmaceuticals Co., Ltd.
            <br />
            {props.jobCode || 'PM-US-XCOP-1328 December 2023'}
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default XKCFooter;
