import fetchWrap from 'fetch-wrap';
// import React from 'react';
// import _ from 'lodash';
// import ReactGA from 'react-ga';
import { updateData } from './redux/authSlice';
import { store } from './store';
// import newsAndViewsVolumeOne from './components/common/NewsAndViews/vol1';
// import newsAndViewsVolumeTwo from './components/common/NewsAndViews/vol2';
// import newsAndViewsVolumeThree from './components/common/NewsAndViews/vol3';
// import newsAndViewsVolumeFour from './components/common/NewsAndViews/vol4';

// let apiUrl = 'http://192.168.1.183:8000';
let apiUrl = 'https://api.xcoprinow.com';

if (process.env.NODE_ENV === 'production') {
  apiUrl = 'https://api.xcoprinow.com';
} else if (process.env.REACT_APP_APIENV === 'DEBUG') {
  apiUrl = 'http://localhost:8000';
}

if (process.env.REACT_APP_APIURL !== undefined) {
  apiUrl = process.env.REACT_APP_APIURL;
}
// apiUrl = 'http://localhost:8000';

const skVeevaApiUrl = process.env.REACT_APP_SKVEEVAAPI;

// export const app_logo = require('./assets/logo.svg');

export const getUrlParams = (name) => {
  const results = new RegExp(`${name}=([^&]*)`).exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]);
};

export const hiddenEmail = (email) => {
  const splitted = email.split('@');
  return `${email[0]}${'*'.repeat(splitted[0].length - 1)}@${splitted[1]}`;
};

const checkAuth = () => {
  return async (url, options, fetch) => {
    const { auth } = store.getState();
    if (!options.headers) {
      window.location.href = '/auth/logout';
    }

    const response = await fetch(url, options);
    if (response.status !== 401) {
      return response;
    }
    // the current user is unauthorized.
    // lets try to refresh the token

    if (!auth.refreshToken) {
      window.location.href = '/auth/logout';
    }
    // lets try to refresh the access token
    const refreshUrl = `${apiUrl}/auth/token/refresh`;
    const data = {
      method: 'POST',
      headers: auth.headers,
      body: JSON.stringify({ refresh: auth.refreshToken })
    };
    const refreshResponse = await fetch(refreshUrl, data);
    if (refreshResponse.status === 401) {
      // the refresh token must be expired too.
      // log out the user and redirect to the login page.
      window.location.href = '/auth/logout';
    }
    const refreshJson = await refreshResponse.json();

    // create/update the new headers
    const headers = {
      ...auth.headers,
      Authorization: `Bearer ${refreshJson.access}`
    };

    // dispatch the refresh token action to update the redux store
    store.dispatch(
      updateData({
        accessToken: refreshJson.access,
        refreshToken: refreshJson.refresh,
        headers: headers
      })
    );
    return fetch(url, { ...options, headers: headers });
  };
};

const states = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District Of Columbia' },
  { value: 'FM', name: 'Federated States Of Micronesia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'GU', name: 'Guam' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PW', name: 'Palau' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VI', name: 'Virgin Islands' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];

// export const fetch = fetchWrap(fetch, [checkAuth()]);

const drTitles = [
  'DO',
  'MD',
  'PHARMD',
  'PHD',
  'Psychologist',
  'AUD',
  'DA',
  'DC',
  'DDS',
  'DH',
  'DMD',
  'DN',
  'DO-S',
  'DPM',
  'Ed.D',
  'MD-S',
  'ND',
  'OD'
];

const figureOne = [
  {
    title: 'FOCAL ONSET 60%<sup>5,7</sup>',
    columns: {
      'MOTORANDNONMOTOR': {
        MOTOR: [
          'Automatisms',
          'Atonic',
          'Tonic',
          'Clonic',
          'Epileptic spasms',
          'Hyperkinetic',
          'Myoclonic'
        ],
        NONMOTOR: ['Autonomic', 'Behavior arrest', 'Cognitive', 'Emotional', 'Sensory']
      },
      'FOCAL TO BILATERAL': ['Tonic-clonic']
    },
    lineThing: true
  },
  {
    title: 'GENERALIZED ONSET 30%<sup>7</sup>',
    columns: {
      MOTOR: [
        'Tonic-clonic',
        'Tonic',
        'Clonic',
        'Myoclonic',
        'Myoclonic-tonic-clonic',
        'Myoclonic-atonic',
        'Atonic',
        'Epileptic spasms'
      ],
      NONMOTOR: ['Typical', 'Atypical', 'Myoclonic', 'Eyelid myoclonia']
    },
    lineThing: false
  },
  {
    title: 'UNKNOWN ONSET 10%<sup>7</sup>',
    columns: {
      MOTOR: ['Tonic-clonic', 'Epileptic spasms'],
      NONMOTOR: ['Behavior arrest'],
      UNCLASSIFIED: ['&mdash;']
    },
    lineThing: true
  }
];

// const NewsAndViewsVolumeTabConfigs = [
//   {
//     volNumber: 1,
//     tabs: newsAndViewsVolumeOne.tabs,
//     headerDate: 'VOLUME 1 • August 2022',
//     headerTop: 'Assessing the Impact of Uncontrolled Epilepsy',
//     headerBottom: 'and the Challenges to Achieving Seizure Control',
//     smallName: 'Dr Klein',
//     nameAndTitle: 'Pavel Klein, MD, FAAN, FAES',
//     imgSrc: newsAndViewsVolumeOne.speakerPhoto
//   },
//   {
//     volNumber: 2,
//     tabs: newsAndViewsVolumeTwo.tabs,
//     headerTop: 'Exploring the Burden of Epilepsy, Including',
//     headerBottom: 'Sudden Unexpected Death in Epilepsy',
//     smallName: 'Dr Rosenfeld',
//     nameAndTitle: 'William Rosenfeld, MD',
//     imgSrc: newsAndViewsVolumeTwo.speakerPhoto,
//     headerDate: 'VOLUME 2 • December 2022'
//   },
//   {
//     volNumber: 3,
//     tabs: newsAndViewsVolumeThree.tabs,
//     headerTop: 'Exploring Treatment Considerations',
//     headerBottom: 'for Achieving Seizure Reduction',
//     headerDate: 'VOLUME 3 • March 2023',
//     smallName: 'Dr Koubeissi',
//     nameAndTitle: 'Mohamad Koubeissi, MD',
//     imgSrc: newsAndViewsVolumeThree.speakerPhoto
//   },
//   {
//     volNumber: 4,
//     tabs: newsAndViewsVolumeFour.tabs,
//     headerTop: 'Real-World Evidence With XCOPRI',
//     headerDate: 'VOLUME 4 • May 2023',
//     smallName: 'Dr Seiden',
//     nameAndTitle: 'Lawrence Seiden, MD',
//     imgSrc: newsAndViewsVolumeFour.speakerPhoto
//   }
// ];

const config = {
  baseApiUrl: apiUrl,
  skVeevaApiUrl: skVeevaApiUrl,
  fetch: fetchWrap(fetch, [checkAuth()]),
  states,
  drTitles,
  figureOne,
  getUrlParams,
  hiddenEmail
  // NewsAndViewsVolumeTabConfigs
};

export default config;
