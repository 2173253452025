import React from 'react';

const IERedirect = () => {
  React.useEffect(() => {
    window.addEventListener('load', () => {
      const t = document.getElementById('ie-text');
      if (t) {
        t.style.opacity = '1 !important';
        t.style.top = '50%';
      }
    });
  });

  return (
    <div
      className="ie-wrapper"
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        background: 'white',
        fontSize: '50px',
        color: 'black'
      }}
    >
      <h3
        id="ie-text"
        style={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 1,
          transition: 'opacity .5s ease-in, top .3s cubic-bezier(0.4, 0, 0.6, 1)',
          fontFamily: 'Helvetica',
          margin: '0 auto',
          width: '100%',
          maxWidth: '1000px',
          color: 'black'
        }}
      >
        This browser is not supported. Please access this site using Chrome, Firefox, Edge or
        Safari.
      </h3>
    </div>
  );
};

export default IERedirect;
