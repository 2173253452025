import React from 'react';

const ErrorPage = () => {
  return (
    <div className="flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
      <h1>Error Page</h1>
    </div>
  );
};

export default ErrorPage;
