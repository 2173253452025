import { combineReducers } from '@reduxjs/toolkit';
// import baseReducer from './baseSlice';
import coreReducer from './coreSlice';
import authReducer from './authSlice';
import dataSlice from './dataSlice';

export default combineReducers({
  core: coreReducer,
  auth: authReducer,
  data: dataSlice
});
