// import { isImmutableDefault } from '@reduxjs/toolkit';
import React, { useState, useEffect } from 'react';
import VizSensor from 'react-visibility-sensor';
import { UpOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import styles from '../../Styles/Isi.module.scss';
import { useWindowSize } from './CustomHooks';

const Isi = (props) => {
  const [imgViz, setImgViz] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [collapsedHeight, setCollapsedHeight] = useState(0);
  const [expandedISIHeight, setExpandedISIHeight] = useState(0);
  const windowSize = useWindowSize();

  useEffect(() => {
    setCollapsedHeight(0);
    if (!isCollapsed) {
      const headerHeight = document.getElementById('itc-header');
      const isiHeight = 500;
      const totalHeight = headerHeight.offsetHeight + isiHeight;
      if (totalHeight > windowSize.height) {
        const reduceBy = totalHeight - windowSize.height;
        setExpandedISIHeight(500 - reduceBy);
      } else {
        setExpandedISIHeight(500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    if (!isCollapsed) {
      const headerHeight = document.getElementById('itc-header');
      const isiHeight = 500;
      const totalHeight = headerHeight.offsetHeight + isiHeight;
      if (totalHeight > windowSize.height) {
        const reduceBy = totalHeight - windowSize.height;
        setExpandedISIHeight(isiHeight - reduceBy);
      } else {
        setExpandedISIHeight(isiHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  const toggleIsi = () => {
    ReactGA.event({
      category: 'ISI',
      action: isCollapsed ? 'Expanded' : 'Collapsed'
    });
    // debugger;
    setIsCollapsed(!isCollapsed);
  };

  const getTop = () => {
    return (
      <div id="isi-height" className={`${styles.text} isi-body-wrapper`}>
        <span style={{ color: '#54565B' }}>
          IMPORTANT SAFETY INFORMATION and INDICATION FOR XCOPRI (cenobamate tablets) CV
        </span>
        <div>
          <span>CONTRAINDICATIONS</span>
          <br />
          <div style={{ marginTop: '5px' }}>
            XCOPRI is contraindicated in any patients with known hypersensitivity to the compound or
            any of the components of the drug product.
          </div>
        </div>
        <div style={{ margin: '5px 0' }}>
          XCOPRI is contraindicated in patients with Familial Short QT syndrome.
        </div>
        {/* {(isDesktop || !isCollapsed) && (
          <div>
            <span style={{ fontFamily: Arial, 'Sans Seriff' }}>WARNINGS AND PRECAUTIONS</span>
            <br /> <b>Drug Reaction with Eosinophilia and Systemic Symptoms (DRESS):</b> Also known
            as Multiorgan hypersensitivity, has been reported in patients taking antiepileptic
            drugs, including XCOPRI. DRESS has been reported, including one fatality, when XCOPRI is
            titrated rapidly (weekly or faster titration). No cases of DRESS were reported in an
            open-label safety study of 1339 partial-onset seizure patients when XCOPRI was initiated
            at 12.5 mg/day and titrated every two weeks. This finding does not establish that the
            risk of DRESS is prevented by a slower titration; however, XCOPRI should be initiated at
            12.5 mg once daily and titrated every two weeks. DRESS typically, although not
            exclusively, presents with fever, rash, and/or lymphadenopathy, in association with
            other organ system involvement. Eosinophilia is often present. If such signs or symptoms
            are present, the patient should be evaluated immediately. XCOPRI should be discontinued
            immediately and not restarted if an alternative etiology for the signs or symptoms
            cannot be established.
          </div>
        )} */}
        {!isCollapsed && !imgViz && getBottom()}
      </div>
    );
  };

  const getBottom = () => {
    return (
      <div className="isi-body-wrapper">
        <div>
          <span>WARNINGS AND PRECAUTIONS</span>
          <br /> <b>Drug Reaction with Eosinophilia and Systemic Symptoms (DRESS):</b> Also known as
          Multiorgan hypersensitivity, has been reported in patients taking antiepileptic drugs,
          including XCOPRI. DRESS has been reported, including one fatality, when XCOPRI is titrated
          rapidly (weekly or faster titration). No cases of DRESS were reported in an open-label
          safety study of 1339 partial-onset seizure patients when XCOPRI was initiated at 12.5
          mg/day and titrated every two weeks. This finding does not establish that the risk of
          DRESS is prevented by a slower titration; however, XCOPRI should be initiated at 12.5 mg
          once daily and titrated every two weeks. DRESS typically, although not exclusively,
          presents with fever, rash, and/or lymphadenopathy, in association with other organ system
          involvement. Eosinophilia is often present. If such signs or symptoms are present, the
          patient should be evaluated immediately. XCOPRI should be discontinued immediately and not
          restarted if an alternative etiology for the signs or symptoms cannot be established.
        </div>
        <div>
          <div style={{ height: 8 }} />
          <b>QT Shortening:</b> XCOPRI can cause shortening of the QT interval. Caution should be
          used when administering XCOPRI and other drugs that shorten the QT interval as there may
          be a synergistic effect on the QT interval that would increase the QT shortening risk.
          <br />
          <div style={{ height: 8 }} />
          <b>Suicidal Behavior and Ideation:</b> Antiepileptic drugs (AEDs), including XCOPRI,
          increase the risk of suicidal thoughts or behavior in patients taking these drugs for any
          indication. Patients treated with any AED for any indication should be monitored for the
          emergence or worsening of depression, suicidal thoughts or behavior, and/or any unusual
          changes in mood or behavior. Advise patients, their caregivers, and/or families to be
          alert for these behavioral changes and report them immediately to a healthcare provider.
          <div style={{ height: 8 }} />
          <b>Neurological Adverse Reactions:</b> XCOPRI causes dose&#8211;dependent increases in the
          neurologic adverse reactions including, dizziness, diplopia, disturbance in gait and
          coordination, somnolence, and fatigue. <br />
          <div style={{ height: 8 }} />
          Prescribers should advise patients against engaging in hazardous activities requiring
          mental alertness, such as operating motor vehicles or dangerous machinery, until the
          effect of XCOPRI is known.
          <div style={{ height: 8 }} />
          <b>Withdrawal of AEDs:</b> As with all antiepileptic drugs, XCOPRI should generally be
          withdrawn gradually because of the risk of increased seizure frequency and status
          epilepticus. But if withdrawal is needed because of a serious adverse event, rapid
          discontinuation can be considered.
        </div>
        <div>
          <div style={{ height: 8 }} />
          <span>MOST COMMON ADVERSE REACTIONS</span>
          <div style={{ height: 6 }} />
          In adult adjunctive therapy placebo-controlled clinical studies, the most common adverse
          reactions that occurred in XCOPRI-treated patients (incidence at least 10% and greater
          than placebo) were somnolence, dizziness, fatigue, diplopia, headache.
        </div>
        <div style={{ height: 8 }} />
        <span>DOSING CONSIDERATIONS</span>
        <div style={{ height: 8 }} />
        <div>
          <div>Dosage adjustment of XCOPRI or other concomitant medications may be necessary.</div>
          <table style={{ marginLeft: '8px' }}>
            <tbody>
              <tr>
                <td
                  valign="top"
                  style={{ fontWeight: 'bold', width: 30, textAlign: 'center', color: 'black' }}
                >
                  &bull;
                </td>
                <td>
                  Consider gradually reducing phenytoin dosages by up to 50% during initial
                  titration.
                </td>
              </tr>
              <tr>
                <td
                  valign="top"
                  style={{ fontWeight: 'bold', width: 30, textAlign: 'center', color: 'black' }}
                >
                  &bull;
                </td>
                <td>
                  Consider reducing dosages of phenobarbital and clobazam as needed when used
                  concomitantly with XCOPRI. When XCOPRI and carbamazepine or lamotrigine are taken
                  concomitantly, consider increasing dosages as needed of carbamazepine or
                  lamotrigine.
                </td>
              </tr>
              <tr>
                <td
                  valign="top"
                  style={{ fontWeight: 'bold', width: 30, textAlign: 'center', color: 'black' }}
                >
                  &bull;
                </td>
                <td>
                  Consider increasing dosages as needed of drugs which are CYP2B6 and CYP3A
                  substrates and decreasing dosages as needed of drugs which are CYP2C19 substrates.
                </td>
              </tr>
              <tr>
                <td
                  valign="top"
                  style={{ fontWeight: 'bold', width: 30, textAlign: 'center', color: 'black' }}
                >
                  &bull;
                </td>
                <td>
                  Effectiveness of hormonal oral contraceptives may be reduced when administered
                  concomitantly with XCOPRI. Women should use additional or alternative non-hormonal
                  birth control.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          Dosage reduction of XCOPRI may be considered in patients with mild to moderate and severe
          renal impairment. XCOPRI use is not recommended in end-stage renal disease.
          <br />
          The maximum recommended daily dose is 200 mg for patients with mild or moderate hepatic
          impairment. XCOPRI use is not recommended in patients with severe hepatic impairment.
        </div>
        <div>
          <div style={{ height: 8 }} />
          <span>DRUG ABUSE</span>
          <div style={{ height: 8 }} />
          XCOPRI is a Schedule V controlled substance.
        </div>
        <div>
          <div style={{ height: 8 }} />
          <span>INDICATION</span>
          <div style={{ height: 8 }} />
          XCOPRI is indicated for the treatment of partial-onset seizures in adult patients.
        </div>
        <div style={{ marginTop: 10 }}>
          <b>
            Please see full{' '}
            <a
              onClick={(e) => e.stopPropagation()}
              rel="noopener noreferrer"
              target="_BLANK"
              href="http://www.xcoprihcp.com/wp-content/uploads/2022/06/xcopri-hcp-pi-med-guide.pdf"
            >
              <span
                style={{
                  fontWeight: 800,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#f78d2a'
                }}
              >
                Prescribing Information
              </span>
            </a>
            .
          </b>
        </div>
      </div>
    );
  };

  const onChange = (isVisible) => {
    // console.log('is visible' + isVisible);
    setImgViz(isVisible);
  };

  // console.log('iscollapsed', isCollapsed);
  // console.log('img', imgViz);
  // console.log('window', windowSize);

  return (
    <VizSensor onChange={onChange} partialVisibility>
      <section className={styles.isi}>
        <div style={{ position: 'relative' }}>
          <div
            className={`wrapper ${styles['isi-wrapper']} ${
              isCollapsed && !imgViz ? 'collapsed' : 'uncollapsed'
            }`}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={toggleIsi}
              id="isi-preview"
              className={`isi-backup ${styles['isi-preview']} ${
                props.higherPreview && isCollapsed ? styles['isi-higher-preview'] : ''
              } ${imgViz ? styles.hide : ''} ${
                isCollapsed && !imgViz ? 'collapsed' : 'fit uncollapsed'
              }`}
              style={{
                zIndex: 9999,
                bottom: !isCollapsed ? collapsedHeight : 0,
                height: !isCollapsed ? expandedISIHeight : ''
              }}
            >
              <UpOutlined
                className={`${styles['isi-arrow']}`}
                style={{
                  transform: `rotate(${!isCollapsed ? 180 : 0}deg)`,
                  marginTop: 5
                }}
              />
              {getTop()}
            </div>
            <div className="bottom-top">{getTop()}</div>
            {getBottom()}
          </div>
        </div>
      </section>
    </VizSensor>
  );
};

export default Isi;
