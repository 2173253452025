import React from 'react';
import { Form, Input } from 'antd';
import { BorderButton } from '../../common';

const SimpleNpiForm = ({ form, toggleNpiLookupModal }) => {
  const onFinish = (values) => {
    console.log('Success !!!!:', values);
    toggleNpiLookupModal();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form name="npiForm" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        name="npi"
        rules={[
          {
            required: true,
            message: 'Please input your NPI!'
          },
          () => ({
            validator: (rule, value) => {
              if (value && !/^\d{10}$/.test(value)) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('NPI needs to be 10 digits.');
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Input />
      </Form.Item>

      <BorderButton htmlType="submit">Search</BorderButton>
    </Form>
  );
};

export default SimpleNpiForm;
