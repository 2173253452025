/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';
import { Typography, Divider, Spin, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import { SessionCard, Header } from '../common';
import IndicationLine from '../common/IndicationLine';
import SideWidgets from '../common/SideWidgets';

import styles from '../../Styles/UpcomingEvents.module.scss';
// import { getSessions } from '../../redux/dataSlice';
// import GreenBox from '../common/GreenBox';
import config from '../../config';
import thumbnail_desktop from '../../img/XMC_Teaser_Video_Desktop.png';
import thumbnail_mobile from '../../img/XMC_Teaser_Video_Mobile.png';

const { Title, Text } = Typography;

const UpcomingEvents = (props) => {
  const [loading, setLoading] = useState(false);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);

  // const [homeSessionsLoaded, setHomeSessionsLoaded] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${config.baseApiUrl}/api/masterclasses`)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((data) => setUpcomingSessions(data));
  }, []);

  const renderVideo = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 25
        }}
      >
        <video
          controls
          width="100%"
          height="auto"
          poster={isMobile ? thumbnail_mobile : thumbnail_desktop}
          style={{
            maxWidth: '800px'
          }}
        >
          <source
            src="https://xcopri-knowledge-center-beta.s3.amazonaws.com/assets/XMC+Teaser+Video_v4.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };

  const renderUpcomingSessions = () => {
    return (
      <div>
        {renderVideo()}
        {upcomingSessions.length ? (
          upcomingSessions.map((session) => {
            return (
              <div
                key={session.id}
                style={{ width: '100%', maxWidth: 800, margin: '0 auto', padding: 20 }}
              >
                <SessionCard key={session.id} session={session} isAnon />
                <Divider size={50} />
              </div>
            );
          })
        ) : (
          <div>
            {!loading && (
              <div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                <Text>
                  There are currently no live XCOPRI masterclass events with live Q&A scheduled.
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`${styles['upcoming-sessions']}`}
      style={{
        // minHeight: 1,
        alignItems: 'center',
        width: '100%'
        // flex: 'unset'
        // marginLeft: 50,
        // marginRight: 50
      }}
    >
      {/* <div className={`${styles['bg-layer']} ${styles.primary}`} /> */}
      <Header
        styles={styles}
        masterClassLogo
        title={() => (
          <div style={{ marginLeft: 20 }}>
            <Title
              style={{
                marginTop: 0,
                marginBottom: 15,
                fontFamily: 'Arial',
                fontWeight: 600
              }}
              className="text__white"
            >
              Register for XCOPRI masterclass!
            </Title>
            {upcomingSessions.length > 0 && (
              <Text style={{ marginTop: 0, marginBottom: 0 }} className="text__white">
                We recognize that you may have questions about XCOPRI. This series was designed to
                enhance your knowledge of XCOPRI, so you are confident in prescribing for your
                patients. <br />
                <br />
                XCOPRI masterclass provides an opportunity to learn from and engage with nationally
                recognized experts in epilepsy. Each class highlights the clinical profile of
                XCOPRI, as well as the expert&apos;s real-world experiences with XCOPRI. <br />
                <br />
                At the end of each virtual class, participate in an “ask the master” session, in
                which you will interact directly with the featured master to gain additional
                insights to inform clinical decision-making for your patients.
              </Text>
            )}
            <IndicationLine />
          </div>
        )}
      />
      <Row justify="space-around">
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Spin
            spinning={loading}
            indicator={
              <LoadingOutlined
                style={{ fontSize: 64, color: '#624b78' }}
                spinning={loading.toString()}
              />
            }
          >
            <div>{renderUpcomingSessions()}</div>
          </Spin>
        </Col>
        {/* <XKCPolicy /> */}
        <Col xl={6} lg={6} md={18} sm={24} xs={24}>
          <SideWidgets
            Login
            Register
            registerModalOpen={registerModalOpen}
            setRegisterModalOpen={setRegisterModalOpen}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingEvents;
