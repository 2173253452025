import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Typography, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { SessionCard, XKCPolicy, Header, XKCMasterclassBar } from '../common';
import TopSessionComponent from './TopSessionComponent';
import config from '../../config';
// import flag from '../../img/flag.png';
// import GreenBox from '../common/GreenBox';
import styles from '../../Styles/MySessions.module.scss';
import IndicationLine from '../common/IndicationLine';

const { Title, Text } = Typography;

const MySessions = (props) => {
  const { mySessions, sessions, user, loaded, loading } = props;
  // console.log(user);
  // const ref = useRef(null);
  const [attendingSessions, setAttendingSessions] = React.useState([]);
  const [topSessionId, setTopSessionId] = React.useState(null);
  // const [mySessionsLoaded, setMySessionsLoaded] = React.useState(false);
  // const [loaded, setLoaded] = React.useState(false);
  //
  useEffect(() => {
    const header = document.getElementById('itc-header');
    const mobileNav = document.querySelector('.bm-menu-wrap');
    mobileNav.style.top = `${header.offsetHeight}px`;
    const headerHeight = header.offsetHeight;
    document.body.style.paddingTop = `${headerHeight}px`;

    const rootEl = document.getElementById('root');
    rootEl.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (
      loaded &&
      !attendingSessions.length &&
      sessions.length &&
      mySessions.length > attendingSessions.length
    ) {
      setAttendingSessions(
        _.compact(
          _.map(mySessions, (sessionId) => {
            return _.find(sessions, { id: sessionId });
          })
        )
      );
      // setMySessionsLoaded(true);
      // setLoaded(true);
    }
  }, [attendingSessions, sessions, mySessions, loaded]);

  const renderMySessions = () => {
    return _.filter(attendingSessions, (session) => session.id !== topSessionId).map((session) => {
      return (
        <div
          key={session.id}
          style={{ width: '100%', maxWidth: 900, margin: '0 auto', padding: 20 }}
        >
          <SessionCard key={session.id} session={session} />
          <Divider size={50} />
        </div>
      );
    });
  };

  const isDr = (degrees) => {
    // TODO
    // review with exit at first find instead of looping over all degrees everytime
    // return _.some(degrees, (degree) => {
    //   return config.drTitles.indexOf(degree) > -1;
    // });
    return _.some(degrees, (degree) => {
      return _.includes(config.drTitles, degree);
    });
  };

  return (
    <>
      <section
        className={`flex flex-column ${styles['my-sessions']}`}
        style={{ minHeight: 1, alignItems: 'center', width: '100%', flex: 'unset' }}
      >
        <Header
          styles={styles}
          title={() => (
            <>
              <Title className="text__white" style={{ marginBottom: 0 }} level={3}>
                Welcome, {isDr(user.degrees) ? 'Dr' : ''} {user.first_name} {user.last_name}
              </Title>
              <Text style={{ marginTop: 0, marginBottom: 0 }} className="text__white">
                Thank you for visiting our site. We hope you’re enjoying everything XCOPRI NOW has
                to offer.
              </Text>
              <IndicationLine />
            </>
          )}
        />
      </section>
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 64, color: '#624b78' }}
            spinning={loading.toString()}
          />
        }
      />
      <div style={{ width: '100%', maxWidth: 900, margin: '0 auto', padding: 20 }}>
        <TopSessionComponent setSessionId={setTopSessionId} />
      </div>
      {attendingSessions.length > 0 && renderMySessions()}
      {attendingSessions.length === 0 && (
        <div style={{ textAlign: 'center' }}>
          <h2>
            <b>You are not registered for any events.</b>
          </h2>
        </div>
      )}
      <XKCMasterclassBar />
      <XKCPolicy />
    </>
  );
};

const mapStateToProps = ({ data, auth }) => {
  const { mySessions, sessions, loaded, loading } = data;
  return { mySessions, sessions, loaded, loading, user: auth.user };
};
export default connect(mapStateToProps, null)(MySessions);
