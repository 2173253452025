import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import config from '../config';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    error: false,
    loaded: false,
    loading: false,
    sessions: [],
    mySessions: [],
    meetingTitleCount: {},
    resources: [],
    resourceCategories: []
  },
  reducers: {
    setData(state, action) {
      Object.assign(state, action.payload);
    },
    setError(state, action) {
      state.error = action.payload.error;
    }
  }
});

export const { setError, setData } = dataSlice.actions;

export const getSessions = () => (dispatch, getState) => {
  dispatch(setData({ loading: true }));
  const { auth } = getState();
  config
    .fetch(`${config.baseApiUrl}/api/sessions`, {
      headers: auth.headers
    })
    .then((response) => {
      response.json().then((sessionData) => {
        dispatch(setData({ ...sessionData, loading: false, loaded: true }));
      });
    });
};

export const getResources = () => (dispatch, getState) => {
  // dispatch(setData({ loading: true }));
  // console.log('XXXXXX');
  const { auth } = getState();
  config
    .fetch(`${config.baseApiUrl}/api/resources`, {
      headers: auth.headers
    })
    .then((response) => {
      response.json().then((resourceData) => {
        dispatch(setData({ ...resourceData }));
      });
    });
};

export const toggleSessionRegistration = (session, updateSuccessStatus, additinalPayload) => (dispatch, getState) => {
  const { auth, data } = getState();
  const wasRegistered = _.includes(data.mySessions, session.id);

  const payload = {
    session: session.id,
    action: wasRegistered ? 'unregister' : 'register',
    isVirtualHybrid: additinalPayload && additinalPayload.attendanceType === 'virtual' ? 1 : 0
  };

  if (additinalPayload) {
    Object.assign(payload, additinalPayload);
  }

  config
    .fetch(`${config.baseApiUrl}/api/toggleregistration`, {
      headers: auth.headers,
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then((response) => {
      if (response.status !== 404) {
        response.json().then((sessionData) => {
          const { mySessions, sessions } = sessionData;

          setTimeout(() => dispatch(setData({ sessions, mySessions })), 1250); // this delay is keeping the session in the list for a bit longer
          updateSuccessStatus(response.status, wasRegistered);
        });
      }
    });
};

export default dataSlice.reducer;
