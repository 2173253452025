import React from 'react';
import UserLookupForm from '../UserLookupForm';

const LoginWidget = () => {
  return (
    <>
      <div className="widget-wrapper login-widget" id="login-widget">
        <UserLookupForm isWidget extraStyles={{ lineHeight: 1.2 }} />

        <hr id="mobile-widget-divider" />
      </div>
    </>
  );
};

export default LoginWidget;
