import React from 'react';
import { Typography } from 'antd';
import BorderButton from './BorderButton';
import laptop from '../../img/laptop.png';

const { Text } = Typography;

const XKCPatientBar = ({ styles }) => {
  return (
    <div
      className="layout full"
      style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
    >
      <div className="bg-layer primary patientBarWrapper">
        <div className={'wrapper fuzz vbooth layout patientBar'}>
          <div className="flex horizontal-center flex-column" style={{ flex: 1 }}>
            <Text
              style={{
                fontFamily: 'Arial',
                color: '#FFFFFF',
                fontSize: 17,
                lineHeight: 1.3,
                textAlign: 'left',
                width: '100%'
              }}
            >
              <h3 style={{ color: 'white' }}>Step Into Your Patients’ Shoes</h3> This experience has
              been designed to give you the opportunity to step into the shoes of adults living with
              partial-onset seizures.
            </Text>
            <div
              role="button"
              tabIndex={0}
              className={'vboothbutton'}
              onClick={() => {
                window.open('https://stepintoxcopri.com/', '_blank');
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
                maxWidth: 300,
                marginLeft: 'none'
              }}
            >
              <BorderButton
                className="button__solid--purple"
                wrapperClasses={'patientbarbtn'}
                text="StepIntoXCOPRI.com"
                wrapperStyle={{ marginTop: 15, maxWidth: 200, background: '#5B2C86' }}
                buttonStyle={{ textTransform: 'none' }}
                gtagtext={{ text: 'StepIntoXCOPRI.com' }}
                gtagid={{ id: 'Step_Into_XCOPRI_External_Link' }}
              />
            </div>
          </div>
          <img
            className="laptop"
            style={{
              flex: 1,
              height: 'auto',
              marginTop: 'auto',
              marginBottom: 'auto'
            }}
            src={laptop}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default XKCPatientBar;
