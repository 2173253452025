import React from 'react';

import { Form, Input, Select, Checkbox, Space, Button } from 'antd';

import FormWrapper from './FormWrapper';

import { BorderButton } from '../../common';

import registerAndProfileFormStyles from '../../../Styles/RegisterAndProfileForm.module.scss';

const { Option } = Select;

const ProfessionalInfoReg = ({
  form,
  error,
  errorCode,
  errorText,
  submitting,
  dropDownValues,
  requiredRule,
  onValuesChange,
  onFormFinish,
  // isNpiLookup,
  // toggleNpiLookupModal,
  isNpiFieldDisabled,
  // isPersonalInfoNpiSearch,
  onVirtualHybridChange,
  isVirtualHybrid,
  toggleRegStep,
  formStyles,
  attendanceType,
  isSessionSpecificRegPage,
  history
}) => {
  const onButtonClick = () => {
    // validate the form and if it validates then set the regStep to professional
    form
      .validateFields()
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        onFormFinish(form.getFieldsValue());
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  return (
    <FormWrapper title={'Professional Information'} progressClass="professionalInfoProgress">
      <Form
        form={form}
        layout="vertical"
        name="register_form"
        initialValues={{
          communication_consent: false,
          patients_treatment_agreement: false,
          speaker_relation_certification: false
        }}
        onValuesChange={onValuesChange}
        onFinish={onFormFinish}
        // onFinishFailed={onFormFinishFailed}
        scrollToFirstError
        className="profile-reg-form"
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <div className="flex">
            <Form.Item
              label="Identify the role that best describes you (select one):"
              required
              rules={[requiredRule]}
              name="attendee_type"
              style={formStyles.formItemStyle}
            >
              <Select>
                <Option value={1}>Licensed Healthcare Provider (prescriber with NPI number)</Option>
                <Option value={6}>Non-Licensed HCP (Non-Prescriber)</Option>
              </Select>
            </Form.Item>

            <div className="npiFormCheck" style={{ ...formStyles.formItemStyle }}>
              {form.getFieldValue('attendee_type') === 1 ? (
                <Form.Item
                  label="NPI"
                  name="npi"
                  required={form.getFieldValue('attendee_type') === 1}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (rule, value) => {
                        if (getFieldValue('attendee_type') === 1) {
                          if (!value) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject('NPI is required.');
                          }
                          if (value && !/^\d{10}$/.test(value)) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject('NPI needs to be 10 digits.');
                          }
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input disabled={isNpiFieldDisabled} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Category"
                  required
                  rules={[requiredRule]}
                  name="attendee_category"
                  // style={formStyles.formItemStyle}
                >
                  <Select>
                    {dropDownValues.attendeeSubTypes &&
                      dropDownValues.attendeeSubTypes.map((attendeeSubtype) => {
                        return (
                          <Option value={attendeeSubtype.subcatcode} key={attendeeSubtype.subcatcode}>
                            {attendeeSubtype.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
            </div>
          </div>
          <div className="flex" style={{ justifyContent: 'space-between' }}>
            <Form.Item
              label="Specialty"
              name="specialty"
              required
              rules={[requiredRule]}
              style={{ ...formStyles.formItemStyle }}
            >
              <Select mode="multiple" placeholder={'Click here to search'}>
                {dropDownValues.specialties &&
                  dropDownValues.specialties.map((specialty) => {
                    return (
                      <Option value={specialty} key={specialty}>
                        {specialty}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Degree or Position"
              name="degree"
              required
              rules={[requiredRule]}
              style={formStyles.formItemStyle}
            >
              <Select mode="multiple" placeholder={'Please select'}>
                {dropDownValues.degrees &&
                  dropDownValues.degrees.map((degree) => {
                    return (
                      <Option value={degree} key={degree}>
                        {degree}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>

          {/* <div className="flex">
            <Form.Item
              label="Other Specialty"
              name="other_specialty"
              style={formStyles.formItemStyle}
              required={!otherSpecialtyDisabled}
              rules={!otherSpecialtyDisabled && [requiredRule]}
            >
              <Input
                maxLength={50}
                disabled={
                  (isRegForm && otherSpecialtyDisabled) ||
                  (!isRegForm && otherSpecialtyDisabled && !profileFormActive)
                }
              />
            </Form.Item>

            <Form.Item
              label="Other Degree"
              name="other_degree"
              style={formStyles.formItemStyle}
              required={!otherDegreeDisabled}
              rules={!otherDegreeDisabled && [requiredRule]}
            >
              <Input
                maxLength={50}
                disabled={
                  (isRegForm && otherDegreeDisabled) ||
                  (!isRegForm && !profileFormActive && otherDegreeDisabled)
                }
              />
            </Form.Item>
          </div> */}

          <Form.Item label="Practice Name/Affiliation" name="affiliation" required rules={[requiredRule]}>
            <Input maxLength={100} />
          </Form.Item>

          <div className="flex">
            <Form.Item
              label="Office Address"
              name="address"
              required
              rules={[requiredRule]}
              style={formStyles.formItemStyle}
            >
              <Input maxLength={50} />
            </Form.Item>

            <Form.Item label="Address 2" name="address2" style={formStyles.formItemStyle}>
              <Input maxLength={50} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item label="Licensed State" name="licensed_state" style={formStyles.formItemStyle}>
              <Select placeholder={'Select a State'}>
                {dropDownValues.states &&
                  dropDownValues.states.map((state) => {
                    return (
                      <Option key={state.abrv} value={state.abrv}>
                        {state.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="License #" name="license_number" style={formStyles.formItemStyle}>
              <Input maxLength={30} />
            </Form.Item>
          </div>

          <>
            <Form.Item
              name="patients_treatment_agreement"
              valuePropName="checked"
              rules={[
                {
                  // eslint-disable-next-line no-confusing-arrow
                  validator: (__, value) => {
                    return value ? Promise.resolve() : Promise.reject(new Error('Please agree to the above'));
                  }
                }
              ]}
              style={{ textAlign: 'left', marginBottom: 0 }}
            >
              <Checkbox
                style={{ marginLeft: 0, lineHeight: 1.1 }}
                className={`${registerAndProfileFormStyles['bottom-checkbox-consent']}`}
              >
                <b>
                  I confirm that I treat patients over the age of 18 in my practice who have partial-onset seizures.*
                </b>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="speaker_relation_certification"
              valuePropName="checked"
              rules={[
                {
                  // eslint-disable-next-line no-confusing-arrow
                  validator: (__, value) => {
                    return value ? Promise.resolve() : Promise.reject(new Error('Please agree to the above'));
                  }
                }
              ]}
              style={{ textAlign: 'left', marginBottom: 0 }}
            >
              <Checkbox
                style={{ marginLeft: 0, lineHeight: 1.1 }}
                className={`${registerAndProfileFormStyles['bottom-checkbox-consent']}`}
              >
                <b>
                  I certify that I am not an employee of the speaker&apos;s own medical practice (same physical
                  location) or a staff member of a facility for which the speaker is a medical director (whose job
                  function it is to educate those within her or his supervisory authority).
                </b>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="communication_consent"
              valuePropName="checked"
              rules={[
                {
                  // eslint-disable-next-line no-confusing-arrow
                  validator: (__, value) => {
                    return value
                      ? Promise.resolve()
                      : Promise.reject(new Error('Please agree to the communication consent'));
                  }
                }
              ]}
              style={{ textAlign: 'left' }}
            >
              <Checkbox
                style={{ marginLeft: 0, lineHeight: 1.1 }}
                className={`${registerAndProfileFormStyles['bottom-checkbox-consent']}`}
              >
                <b>
                  I understand and agree that any information that I provide may be used by SKLSI in accordance with
                  SKLSI&apos;s{' '}
                  <a
                    href="https://www.sklifescienceinc.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#f78d2a', textDecoration: 'underline' }}
                  >
                    Privacy Policy
                  </a>
                  . I understand I may opt out of receiving information within future communications.*
                  <br />
                  <br />
                  *By submitting this form, I agree to the site&apos;s{' '}
                  <a
                    href="https://www.sklifescienceinc.com/terms-of-use/ "
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#f78d2a', textDecoration: 'underline' }}
                  >
                    Terms of Use
                  </a>
                  .
                </b>
              </Checkbox>
            </Form.Item>
            {isVirtualHybrid && (
              <Form.Item
                name="attendanceType"
                valuePropName="checked"
                rules={[
                  {
                    // eslint-disable-next-line no-confusing-arrow
                    validator: () => {
                      // console.log('attendanceType', attendanceType);
                      return attendanceType ? Promise.resolve() : Promise.reject(new Error('Please select an option'));
                    }
                  }
                ]}
                style={{ textAlign: 'left', marginBottom: 0 }}
                label={
                  <b style={{ fontWeight: 'bold' }}>
                    The program you are registering for is hybrid and can accomodate both in-person and virtual
                    attendees. Please confirm how you are planning to attend by choosing from the options below.
                  </b>
                }
              >
                <Checkbox
                  style={{ marginLeft: 0, lineHeight: 1.1 }}
                  onChange={onVirtualHybridChange('in-person')}
                  checked={attendanceType === 'in-person'}
                >
                  <span style={{ fontWeight: 'bold' }}>In Person</span>
                </Checkbox>
                <Checkbox
                  style={{ marginLeft: 0, lineHeight: 1.1 }}
                  onChange={onVirtualHybridChange('virtual')}
                  checked={attendanceType === 'virtual'}
                >
                  <span style={{ fontWeight: 'bold' }}>Virtual</span>
                </Checkbox>
              </Form.Item>
            )}
          </>

          {error && (
            <p style={{ color: 'red', textAlign: 'center' }}>
              {errorText || 'Something went wrong... please try again later!'}
            </p>
          )}

          <div className="flex" style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Form.Item>
              <Button
                className={`${registerAndProfileFormStyles['register-back-button']}`}
                onClick={() => {
                  if (errorCode === 400) {
                    if (isSessionSpecificRegPage) {
                      toggleRegStep('landing');
                    } else {
                      history.push('/auth');
                    }
                  } else {
                    toggleRegStep('personalInfo');
                  }
                }}
                gtagtext={{ text: 'Register Back' }}
                gtagid={{ id: 'Register_Back' }}
              >
                Back
              </Button>
            </Form.Item>

            <Form.Item>
              <BorderButton
                text={'Submit'}
                onClick={onButtonClick}
                disabled={submitting}
                wrapperClasses={`${registerAndProfileFormStyles['register-submit-button']}`}
                // wrapperStyle={{ minWidth: 350 }}
                gtagtext={{ text: 'Register' }}
                gtagid={{ id: 'Register_Complete' }}
              />
            </Form.Item>
          </div>
        </Space>
        {/* <BorderButton wrapperStyle={{ margin: '0 auto' }} onClick={onButtonClick}>
          Submit
        </BorderButton> */}
      </Form>
    </FormWrapper>
  );
};

export default ProfessionalInfoReg;
