import React from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router';
// import { Redirect } from 'react-router-dom';
import { logoutUser } from '../../redux/authSlice';

const LogOut = (props) => {
  // debugger;
  setTimeout(() => {
    props.logoutUser();
    props.history.push('/auth');
    // <Redirect to={'/auth/login'} />;
  }, 500);
  // props.history.push('/auth/login');

  // window.location.href = '/auth/login';
  // return <Redirect to={'/auth/login'} />;
  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default connect(null, { logoutUser })(LogOut);
