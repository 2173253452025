import React from 'react';
// import { Divider } from 'antd';
import { connect } from 'react-redux';
import { SessionCardIcons } from '../common/SessionCardSections';
import UserLookupForm from '../common/UserLookupForm';

import { updateData } from '../../redux/authSlice';

import config from '../../config';

import regStyles from '../../Styles/RegisterAndProfileForm.module.scss';

const SessionComponent = (props) => {
  const [session, setSession] = React.useState(null);
  // eslint-disable-next-line no-shadow
  const { code, updateData, isAuth } = props;

  React.useEffect(() => {
    if (!session) {
      const request = fetch(`${config.baseApiUrl}/api/sessionbycode/${code}`);
      request.then((response) => {
        if (response.status === 404) {
          return;
        }
        response.json().then((meeting) => {
          setSession(meeting);
          // setValidSession(true);
          updateData({
            regSessionType: meeting.meetingtype,
            isVirtualHybrid: meeting.is_virtual_hybrid
          });
        });
      });
    }
  }, [code, session]); // eslint-disable-line

  if (!session) {
    return '';
  }

  if (isAuth) {
    return (
      <div className={`${regStyles.regSessionCard}`}>
        <SessionCardIcons session={session} preTitle="EVENT" />

        <div style={{ height: 2, borderTop: '1px solid lightGray', margin: '40px 20px' }} />
        <UserLookupForm />
      </div>
    );
  }

  return <SessionCardIcons session={session} preTitle="EVENT" />;
};

export default connect(null, { updateData })(SessionComponent);
