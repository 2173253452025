import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = (props) => {
  const { videoUrl } = props;

  return (
    <div className="flex vertical-center horizontal-center" style={{ height: '80vh' }}>
      {videoUrl ? (
        <ReactPlayer
          width={'90%'}
          height={'90%'}
          url={videoUrl}
          controls
          playing
          config={{
            file: {
              attributes: {
                // onContextMenu: (e) => e.preventDefault(),
                controlsList: 'nodownload'
              }
            }
          }}
        />
      ) : (
        <h1 className="title--purple">Video not available</h1>
      )}
    </div>
  );
};

export default VideoPlayer;
